import { toast } from 'react-toastify';
import i18n from 'i18next';
import { errorMessages } from '../constants/errorMessages';

function extractData(inputString) {
    const cityMatch = inputString.match(/city:(.*?);/);
    const teacherMatch = inputString.match(/teacher:(.*?);/);
    const studentMatch = inputString.match(/student:(.*?)(?:$|;)/);

    const city = cityMatch ? cityMatch[1].trim() : null;
    const teacher = teacherMatch ? teacherMatch[1].trim() : null;
    const student = studentMatch ? studentMatch[1].trim() : null;

    const cityString = city ? `\n${i18n.t('modals.city')}: ${city}` : '';
    const teacherString = teacher
        ? `\n${i18n.t('table.teacher')}: ${teacher}`
        : '';
    const studentString = student
        ? `\n${i18n.t('olympiad.student')}: ${student}`
        : '';

    return `${cityString}${teacherString}${studentString}`;
}

function extractNumberFromString(inputString) {
    const regex = /Min price: (\d+)/;
    const match = regex.exec(inputString);

    if (match && match[1]) {
        return parseInt(match[1], 10);
    } else {
        return null; // Если число не найдено, возвращаем null или можно выбрать другое значение по умолчанию
    }
}

function getError(error, onlyMessage = false) {
    let save_error = JSON.parse(JSON.stringify(error));
    if (error.message) {
        for (let message in errorMessages) {
            const messageItem = error.message.includes(message);
            if (messageItem) {
                error.message = errorMessages[message];
                break;
            }
        }
    }

    let translate = {
        'report is empty': i18n.t('error.report is empty'),
        this_type_of_book_is_already_attached: i18n.t(
            'error.this_type_of_book_is_already_attached'
        ),
        unable_to_login: i18n.t('error.unable_to_login'),
        'It is impossible to delete a group if there are students in it':
            i18n.t(
                'error.It is impossible to delete a group if there are students in it'
            ),
        'The final answer': 'biba',
        'The first operation cannot be multiplication': 'biba',
        'results in an intermediate answer less than zero': i18n.t(
            'error.results in an intermediate answer less than zero'
        ),
        'This group does not belong to the user who is requesting': i18n.t(
            'error.This group does not belong to the user who is requesting'
        ),
        'later than whoever studies in Soroban': i18n.t(
            'error.later than whoever studies in Soroban'
        ),
        'already in this family for confirmation': i18n.t(
            'error.already in this family for confirmation'
        ),
        'this student is already listed as a referral': i18n.t(
            'error.this student is already listed as a referral'
        ),
        'the apprentice created earlier than the attached apprentice': i18n.t(
            'error.the apprentice created earlier'
        ),
        "couldn't get Uid book": i18n.t("error.couldn't get Uid book"),
        "couldn't get fop": i18n.t('error.no fop found'),
        'Invalid parameter phone': i18n.t('error.Invalid parameter phone'),
        "couldn't get school": i18n.t("error.couldn't get school"),
        'fop keys is not defined': i18n.t('error.fop keys is not defined'),
        'Wrong password': i18n.t('error.wrong_password'),
        'Wrong email': i18n.t('error.wrong_email'),
        'Invalid data passed': i18n.t('error.invalid_data_passed'),
        'transaction_key is outdated': i18n.t(
            'error.transaction_key_is_outdated'
        ),
        'transaction_key was corrupted': i18n.t(
            'error.transaction_key_was_corrupted'
        ),
        'Not find user': i18n.t('error.not_find_user'),
        'refresh_token is required': i18n.t('error.refresh_token_is_required'),
        'Token recovery is not subject to': i18n.t(
            'error.token_recovery_is_not_subject_to'
        ),
        'Incorrect data': i18n.t('error.incorrect_data'),
        'Method not allowed': i18n.t('error.Method not allowed'),
        'token is required': i18n.t('error.token_is_required'),
        'Token is outdated': i18n.t('error.token_is_outdated'),
        'Unauthorized, token was corrupted': i18n.t(
            'error.unauthorized_token_was_corrupted'
        ),
        'Failed to fetch': i18n.t('error.failed_to_fetch'),
        'Wrong templates data': i18n.t('error.Wrong templates data'),
        'Only zero': i18n.t('payments.enter_payment_amount'),
        'Error assign uid': i18n.t('error.Error assign uid'),
        'This uid does not exist': i18n.t('error.This uid does not exist'),
        'Uid was already attached to another student': i18n.t(
            'error.Uid was already attached to another student'
        ),
        'No homework found for this day': i18n.t(
            'error.No homework found for this day'
        ),
        'The teacher has students': i18n.t('error.the_teacher_has_students'),
        'Token has been changed': i18n.t('error.Token has been changed'),
        'Error getting student office': i18n.t(
            'error.Error getting student office'
        ),
        'The student received a card within a week': i18n.t(
            'error.The student received a card within a week'
        ),
        'Transaction error: the franchisee is currently processing a transaction':
            i18n.t(
                'error.the franchisee is currently processing a transaction'
            ),
        'Error: balance student is negative': i18n.t(
            'error.balance student is negative'
        ),
        'family discount': i18n.t('error.family discount'),
        'uid NOT VALID TO student_id': i18n.t(
            'error.uid NOT VALID TO student_id'
        ),
        'UNKNOWN LINK': i18n.t('error.UNKNOWN LINK'),
        'uid IS NOT EXISTS': i18n.t('error.uid IS NOT EXISTS'),
        'uid ALREADY LINKED TO student_id': i18n.t(
            'error.uid ALREADY LINKED TO student_id'
        ),
        '_RE_LINKED TO': i18n.t('users._RE_LINKED TO'),
        'amount is not correct': 'amount is not correct',
        'quantity to much': 'quantity to much',
        'balance is not correct': i18n.t('error.balance is not correct'),
        'integer out of range': i18n.t('error.integer out of range'),
    };

    if (error.message) {
        if (error.message === 'The final answer') {
            translate['The final answer'] = `${i18n.t(
                'error.digits_task_error_0'
            )} ${
                save_error.message.split('The final answer: ')[1].split(',')[0]
            } ${i18n.t('error.digits_task_error_1')}`;
        }

        if (error.message === 'The first operation cannot be multiplication') {
            translate[
                'The first operation cannot be multiplication'
            ] = `${i18n.t('error.mult_task_error')} - ${
                save_error.message.split(
                    'The first operation cannot be multiplication - '
                )[1]
            }`;
        }
        if (
            error.message === 'results in an intermediate answer less than zero'
        ) {
            translate[
                'results in an intermediate answer less than zero'
            ] = `${i18n.t('error.template_error')} ${
                save_error.message.split('Operations: ')[1].split(' results')[0]
            } ${i18n.t(
                'error.results in an intermediate answer less than zero'
            )}`;
        }
        if (error.message === 'Uid was already attached to another student') {
            const extractedData = extractData(save_error.message);
            translate[
                'Uid was already attached to another student'
            ] = `${i18n.t(
                'error.Uid was already attached to another student'
            )} ${extractedData}`;
        }

        if (
            error.message ===
                'Your price is less than set by the boss of the country price' &&
            extractNumberFromString(save_error.message)
        ) {
            const extractedData = extractNumberFromString(save_error.message);
            translate[
                'Your price is less than set by the boss of the country price'
            ] = `${i18n.t(
                'error.Your price is less than set by the boss of the country price'
            )} ${extractedData}`;
        }
        if (error.message.includes('amount is not correct')) {
            translate['amount is not correct'] = `${i18n.t(
                'error.amount is not correct'
            )}`;
            error.message = 'amount is not correct';
        }
        // +++++++++++++++++++++++
        if (error.message.includes('quantity to much')) {
            translate['quantity to much'] = `${i18n.t(
                'error.quantity to much'
            )}`;
            error.message = 'quantity to much';
        }

        if (error.message.includes('Unauthorized')) {
            translate['unauthorized'] = `${i18n.t('error.unauthorized')}`;
            error.message = 'unauthorized';
        }
        if (error.message.includes('ERROR: balance is not correct -')) {
            const key = 'balance is not correct';
            error.message = 'balance is not correct';
        }
    }
    // +++++++++++++++++++++++++
    let message;

    if (error.message) {
        // Check if there is a translation for the specific error message
        if (translate[error.message]) {
            message = translate[error.message];
        } else {
            // If not, use the generic "unknown_error" translation
            message = i18n.t('error.unknown_error');
        }
    } else {
        // Check if there is a translation for the error itself (no message property)
        if (translate[error]) {
            message = translate[error];
        } else {
            // If not, use the generic "unknown_error" translation
            message = i18n.t('error.unknown_error');
        }
    }
    if (onlyMessage) {
        return message;
    }
    const activeToast = toast.isActive(
        `${message.replace(/\s/g, '')}${Number(new Date())}${Number(
            new Date()
        )}`
    );
    if (!activeToast)
        toast.error(`${message}`, {
            toastId: `${message.replace(/\s/g, '')}${Number(new Date())}`,
            data: { ...save_error },
        });
}

function getSuccess(success) {
    const message = !success ? i18n.t('text.success') : success;
    const activeToast = toast.isActive(message.replace(/\s/g, ''));
    if (!activeToast)
        toast.success(`${message}`, {
            toastId: `${message.replace(/\s/g, '')}${Number(new Date())}`,
        });
}

function getNotification(text) {
    const activeToast = toast.isActive(text.replace(/\s/g, ''));
    if (!activeToast)
        toast.success(`${text}`, {
            toastId: `${text.replace(/\s/g, '')}${Number(new Date())}`,
        });
}

function getInfo(text) {
    const activeToast = toast.isActive(text.replace(/\s/g, ''));
    if (!activeToast)
        toast.info(`${text}`, {
            toastId: `${text.replace(/\s/g, '')}${Number(new Date())}`,
        });
}
function getErrorNotification(text) {
    const activeToast = toast.isActive(text.replace(/\s/g, ''));
    if (!activeToast)
        toast.error(`${text}`, {
            toastId: `${text.replace(/\s/g, '')}${Number(new Date())}`,
        });
}
function getErrorTranslate(text) {
    const message = i18n.t(text);
    const activeToast = toast.isActive(message.replace(/\s/g, ''));
    if (!activeToast)
        toast.error(`${message}`, {
            toastId: `${message.replace(/\s/g, '')}${Number(new Date())}`,
            data: {},
        });
}
export {
    getError,
    getSuccess,
    getNotification,
    getErrorNotification,
    getErrorTranslate,
    getInfo,
};
