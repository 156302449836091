import React, { Fragment } from 'react';
import CitiesService from '../../services/cities.service';
import SCHEMAS from '../../constants/modalSchemas';

import ModelService from './../../services/models.service';
import { connect } from 'react-redux';
import { openRequestModal } from '../../actions/modals';
import { bindActionCreators } from 'redux';
import ComponentTable from '../../components/ComponentTable';
import { getCities } from '../../actions/cities';
import { withTranslation } from 'react-i18next';

import { Add, Edit, Delete } from '@material-ui/icons';

class CitiesPage extends React.Component {
    componentDidMount() {}
    render() {
        const { t, i18n } = this.props;
        const actions = [
            {
                title: t('modals_name.edit'),
                onClick: (id, row) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            schema: SCHEMAS.requestDelete,
                            header: `${t('modals_name.edit')} | ${
                                row.lang_def[i18n.language]
                            }`,
                            modaltype: 'EditCityModal',
                        },
                    }),
                Icon: Edit,
            },
            {
                title: t('buttons.delete'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            schema: SCHEMAS.requestDelete,
                            header: t('buttons.delete'),
                            modaltype: 'DeleteCityModal',
                        },
                    }),
                Icon: Delete,
            },
        ];
        return (
            <Fragment>
                <h2 className="container-header"> {t('sidebar.cities')} </h2>
                <ComponentTable
                    source={{
                        getData: (page, size, sort, filter) =>
                            CitiesService.CitiesList(false, {
                                page: page || 0,
                                size: size || 10,
                                orderBy: (sort && sort.orderBy) || null,
                                order: (sort && sort.order) || null,
                                filter,
                            }),
                        getModel: () => ModelService.CitiesList(false),
                    }}
                    actions={actions}
                    selectable={true}
                    enableFilter={true}
                    title={t('modals.list')}
                    enableSettings
                    toolbarvisible={true}
                    actionsButton={[
                        {
                            title: t('table.add'),
                            Icon: Add,
                            onClick: () =>
                                this.props.openRequestModal({
                                    opened: true,
                                    data: {
                                        header: t('modals_name.add_city'),
                                        modaltype: 'AddCityModal',
                                    },
                                }),
                        },
                    ]}
                />
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({ user: state.user, cities: state.cities }),
    (dispatch) => bindActionCreators({ getCities, openRequestModal }, dispatch)
);

export default withTranslation()(widthConnect(CitiesPage));
