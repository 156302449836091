import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCitiesBalances } from '../../actions/bank';
import BankTransactionsService from '../../services/bank-transactions.service';
import PropTypes, { array } from 'prop-types';
import { useTranslation } from 'react-i18next';
import usePhoneCheck from '../../hooks/usePhoneCheck';
import BankMobileBody from '../../components/ComponentTable/Table/BankMobileBody';

import {
    Stack,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    TextField,
    Autocomplete,
    CircularProgress,
    Button,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { toast } from 'react-toastify';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BackButton from '../../components/BalanceComponent/BackButton';

const headCells = [
    {
        id: 'rowId',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.cityId',
    },
    {
        id: 'city',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.city',
    },
    {
        id: 'surname',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.surname',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.name',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.balance',
    },
    {
        id: 'cityStatus',
        numeric: false,
        disablePadding: false,
        label: 'bankFranchPage.status',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'bankFranchPage.quantity',
    },
    {
        id: 'bankAccount',
        numeric: true,
        disablePadding: false,
        label: 'bankFranchPage.bankAccount',
    },

];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected } = props;
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <Typography>{t(headCell.label)}</Typography>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected, onSuccessClick, loading } = props;
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                mt: 2,
                mb: 2,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} {t('bankFranchPage.selected')}
                </Typography>
            ) : (
                <></>
            )}
            {numSelected > 0 ? (
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    {loading ? <CircularProgress size={20} /> : null}
                    <Tooltip title={t('bankStudentsPage.tooltipTitle')}>
                        <IconButton onClick={onSuccessClick} disabled={loading}>
                            <DoneOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ) : (
                <></>
            )}
        </Toolbar>
    );
}

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSuccessClick: PropTypes.func.isRequired,
};

function getRowData(data) {

    return data
        ? data.map((item) => ({
              error: false,
              rowId: item.franch_id,
              cityId: item.city_id,
              name: item.franch_first_name || '',
              surname: item.franch_last_name || '',
              city: item.city_name.split("|||")[1] || '',
              balance: Number(item.franch_balance)+Number(item.teach_balance)+Number(item.student_balance),
              quantity: 300,
              cityStatus: "",
          }))
        : [];
}

export default function CityBalances() {
    const [selected, setSelected] = useState([]);
    const [franch, setFranch] = useState('');
    const [city, setCity] = useState('');
    const [cityStatus, setCityStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorStates, setErrorStates] = useState({});

    const mobile = usePhoneCheck();
    const history = useHistory();
    const { t } = useTranslation();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;

    const citiesBalancesInfo = useMemo(() => {
        return getRowData(user.customer.citiesBalances);
    }, [user]);

    useEffect(() => {
        setRows(citiesBalancesInfo);
    }, [citiesBalancesInfo]);

    const [rows, setRows] = useState(citiesBalancesInfo);

    const dispatch = useDispatch();

    const fetchCitiesBalances = async () => {
        await dispatch(setCitiesBalances());
    };

    useEffect(() => {
        fetchCitiesBalances();
    }, []);

    const handleQuantityChange = (id, value) => {
        // Знаходимо індекс рядка з вказаним id
        const rowIndex = rows.findIndex((row) => row.rowId === id);
        // Створюємо новий екземпляр масиву rows, щоб уникнути мутації стану безпосередньо
        const newRows = [...rows];
        // Оновлюємо значення 'quantity' для конкретного рядка
        newRows[rowIndex] = {
            ...newRows[rowIndex],
            quantity: parseFloat(value) || '',
        };
        // Оновлюємо значення 'quantity' для конкретного рядка
        setRows(newRows);

        // Оновлюємо стан помилок
        setErrorStates((prevState) => ({
            ...prevState,
            [id]: value === '',
        }));
    };

     const renderQuantityInput = (id, quantity, onChange) => (
        <TextField
            id={`quantity-input-${id}`}
            value={quantity}
            type="number"
            color="warning"
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            required
            error={isSelected(id) && (quantity === '' || quantity === null)}
            helperText={isSelected(id) && (quantity === '' || quantity === null) ? t('bankStudentsPage.fillField') : ''}
            onChange={(e) => {
                let value = e.target.value;
                value = value.replace(',', '.');

                const regex = /^\d*\.?\d{0,2}$/;
                if (!regex.test(value)) {
                    value = value.slice(0, -1);
                }
                onChange(id, value);
            }}
            onKeyDown={(e) => {
                if (['-', '+', '=', '*', '/', '.'].includes(e.key)) {
                    e.preventDefault();
                }
            }}
            inputProps={{
                min: 0,
                step: 0.01,
            }}
            style={{ width: '70px' }}
        />
    );

    const handleSelectAllClick = (event) => {
        if (loading) return;
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.rowId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        if (loading) return;

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selected, id];
        } else if (selectedIndex >= 0) {
            newSelected = selected.filter((itemId) => itemId !== id);
        }

        setSelected(newSelected);

        setErrorStates((prevState) => ({
            ...prevState,
            [id]: false, // Очищення стану помилки при кліку на чекбокс
        }));
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    async function handleSuccessClick() {

        // Оберемо транзакції з 0 баланс, якщо такі є виходимо з функції
        const hasEmptyQuantity = rows
            .filter((row) => isSelected(row.rowId))
            .some(row => !row.quantity);

        if (hasEmptyQuantity) {
            toast.error(t('bankStudentsPage.hasEmptyQuantity'));
            return;
        };

    // Відфільтровуємо транзакції для підтвердження
        const data = rows
            .filter((row) => isSelected(row.rowId))
            .map((el) => ({
                amount: Number(el.quantity),
                customerId: Number(el.rowId), 
                note: '',
            }));

        const totalAmount = data.reduce((accumulator, user) => accumulator + user.amount, 0)
        if (totalAmount <= balance) {
            await Promise.all(
                data.map(async (item) => {
                    try {
                        await BankTransactionsService.TransactionById(item)
 
                    } catch (error) {
                        console.log(error);
                    }
                })
            ); 
        } else {
            toast.error(t('sorokoinsForHomework.notEnoughMoney'));
        }
         setSelected([]);
    }

    // Фільтрація
    const handleFilterChange = (newFranch, newCity, newStatus) => {
        if (loading) return;

        let filteredRows = citiesBalancesInfo;

        if (newFranch) {
            filteredRows = filteredRows.filter((row) => row.surname === newFranch);
        }
        if (newCity) {
            filteredRows = filteredRows.filter((row) => row.city === newCity);
        }
        if (newStatus) {
            filteredRows = filteredRows.filter((row) => row.cityStatus === newStatus);
        }

        setRows(filteredRows);
    };

    const handleBankAccountClick = (id) => {
        history.push(`/users/bank/${id}/franch`);
    };


    return (
        <Box sx={{ width: '100%' }}>
            <BackButton />
            <Paper sx={{ width: '100%', mb: 2, p: 3 }}>
                <Stack direction={mobile ? "column" : "row"} justifyContent="flex-start" spacing={2}>
                    <Autocomplete
                        id="controllable-states-demo"
                        options={rows
                            .map((option) => option.surname)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newFranch) => handleFilterChange(newFranch, city, cityStatus)}
                        inputValue={franch}
                        onInputChange={(event, newInputValue) => {
                            setFranch(newInputValue);
                        }}
                        sx={{ width: 260, marginBottom: '10px' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('bankFranchPage.surname')}
                            />
                        )}
                    />
                    <Autocomplete
                        id="controllable-states-demo"
                        options={rows
                            .map((option) => option.city)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newCity) => handleFilterChange(franch, newCity, cityStatus)}
                        inputValue={city}
                        onInputChange={(event, newInputValue) => {
                            setCity(newInputValue);
                        }}
                        sx={{ width: 260, marginBottom: '10px' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('bankFranchPage.city')}
                            />
                        )}
                    />
                    <Autocomplete
                        id="controllable-states-demo"
                        options={rows
                            .map((option) => option.cityStatus)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newStatus) => handleFilterChange(franch, city, newStatus)}
                        inputValue={cityStatus}
                        onInputChange={(event, newInputValue) => {
                            setCityStatus(newInputValue);
                        }}
                        sx={{ width: 260, marginBottom: '10px' }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('bankFranchPage.status')}
                            />
                        )}
                    />
                </Stack>
                {/* Передаємо кількість обраних рядків та функцію видалення в панель інструментів */}

                <EnhancedTableToolbar
                    numSelected={selected.length}
                    onSuccessClick={handleSuccessClick}
                    loading={loading}
                />
                {mobile ? (
                    <BankMobileBody
                        rows={rows}
                        headCells={headCells}
                        isSelected={isSelected}
                        handleSelectAllClick={handleSelectAllClick}
                        handleQuantityChange={handleQuantityChange}
                        handleClick={handleClick}
                        renderQuantityInput={renderQuantityInput}
                        handleBankAccountClick={handleBankAccountClick}
                    />
                ) : (
                    <TableContainer style={{ maxHeight: '59vh' }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        // size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {rows.map((row, index) => {
                                    const isItemSelected = isSelected(
                                        row.rowId
                                    );
                                    const labelId = ` enhanced - table - checkbox - ${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.rowId}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                            style={{
                                                transition: 'all 0.2s',
                                                backgroundColor: row.error
                                                    ? '#ff000033'
                                                    : 'transparent',
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.rowId
                                                        )
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby':
                                                            labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.cityId}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.city}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.surname}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.balance}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.cityStatus}
                                            </TableCell>
                       
                                            <TableCell align="left">
                                                {renderQuantityInput(
                                                    row.rowId,
                                                    row.quantity,
                                                    handleQuantityChange
                                                )}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Tooltip
                                                    title='SOROBANK'
                                                    placement="bottom"
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            handleBankAccountClick(
                                                                row.rowId
                                                            )
                                                        }
                                                        style={{
                                                            height: '40px',
                                                            borderRadius: '20px',
                                                            fontSize: '14px',
                                                        }}
                                                        aria-label="SOROBANK"
                                                        type="button"
                                                        startIcon={
                                                            <AccountBalanceIcon
                                                            // style={{ color: '#58a315' }}
                                                            />
                                                        }
                                                    >
                                                        SOROBANK
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </Box>
    );
}

