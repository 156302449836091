import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCities } from '../../actions/cities';
import { getOffices } from '../../actions/offices';
import { getCountries } from '../../actions/countries';
import CountriesService from './../../services/countries.service';
import CitiesService from './../../services/cities.service';
import { closeRequestModal, openRequestModal } from '../../actions/modals';
import { bindActionCreators } from 'redux';
import CountUp from 'react-countup';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../layout/Dash/Dash.css';
import { withTranslation } from 'react-i18next';
import ComponentTable from '../ComponentTable';
import BooksService from '../../services/books.service';
import { ExitToApp } from '@material-ui/icons';
import { emulation } from '../../actions/user';
import ModelsService from '../../services/models.service';
// import { Grid, Stack, Typography } from "@mui/material";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BalanceComponent from '../BalanceComponent';
import CurrencyControl from './CurrencyControl';
import { Stack } from '@mui/material';

class DashAdminView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: -1,
            cities: -1,
            offices: -1,
        };
    }
    componentDidMount() {
        this.getData();
    }

    async getData() {
        CountriesService.CountriesList().then((res) => {
            this.setState({ countries: res.count });
        });
        CitiesService.CitiesList().then((res) => {
            this.setState({ cities: res.count });
        });
    }

    getEmulationRoleId() {
        return this.props.user &&
            this.props.user.emulation &&
            this.props.user.emulation.length
            ? +this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id + 1
            : +this.props.user.info.role_id === 10
            ? 2
            : +this.props.user.info.role_id + 1;
    }

    mockModelBookAnalytics() {
        return {
            list: [
                { code: 'key', label: '№', type: 'number' },
                { code: 'city', label: 'table.city', type: 'string' },
                {
                    code: 'connected_count',
                    label: 'table.connected_count',
                    type: 'number',
                },
                {
                    code: 'unconnected_count',
                    label: 'table.unconnected_count',
                    type: 'number',
                },
                { code: 'country', label: 'table.country', type: 'string' },
                {
                    code: 'teacher_name',
                    label: 'table.teacher',
                    type: 'string',
                },
            ],
        };
    }
    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <BalanceComponent />
                <div className="container-cards">
                    <Grid container spacing={1} style={{ marginBottom: '5px' }}>
                        <Grid item>
                            <div className="cards">
                                <Link to="/countries">
                                    <div className="card-wrapper">
                                        <div className="card-right">
                                            <Typography variant={'h3'}>
                                                {this.state.countries === -1 ? (
                                                    <CircularProgress
                                                        size={24}
                                                        color="inherit"
                                                    />
                                                ) : (
                                                    <CountUp
                                                        duration={2.75}
                                                        end={
                                                            this.state.countries
                                                        }
                                                    />
                                                )}
                                            </Typography>
                                            <Typography variant={'caption'}>
                                                {t('dashboard.total_country')}
                                            </Typography>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </Grid>
                        <Grid item>
                            <div className="cards">
                                <Link to="/cities">
                                    <div className="card-wrapper">
                                        <div className="card-right">
                                            <Typography variant={'h3'}>
                                                {this.state.cities === -1 ? (
                                                    <CircularProgress
                                                        size={24}
                                                        color="inherit"
                                                    />
                                                ) : (
                                                    <CountUp
                                                        duration={2.75}
                                                        end={this.state.cities}
                                                    />
                                                )}
                                            </Typography>
                                            <Typography variant={'caption'}>
                                                {t('dashboard.total_city')}
                                            </Typography>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Stack spacing={1}>
                    <CurrencyControl />

                    <ComponentTable
                        source={{
                            getData: (page, size, sort, filter) =>
                                BooksService.getBooksAnalytics(false, {
                                    role_id: this.getEmulationRoleId(),
                                    page: page || 0,
                                    size: size || 10,
                                    orderBy:
                                        (sort && sort.orderBy) || 'lesson_day',
                                    order: (sort && sort.order) || 'asc',
                                    filter,
                                }),
                            getModel: () =>
                                ModelsService.BooksAnalyticsModels(),
                        }}
                        actions={[
                            {
                                title: t('table.emulation'),
                                onClick: (_, row) =>
                                    this.props.emulation({
                                        id: row.teacher_id,
                                        path: window.location.pathname,
                                    }),
                                Icon: ExitToApp,
                            },
                        ]}
                        title={`Аналітика по книжкам`}
                        toolbarvisible={true}
                        enableFilter={true}
                        typeTable="books_analytics"
                        headerTable={t('table.book')}
                    />
                </Stack>
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        cities: state.cities,
        offices: state.offices,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                emulation,

                getCities,
                getOffices,
                getCountries: getCountries,
                openRequestModal,
                closeRequestModal,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(DashAdminView));
