import React, { Fragment } from "react";
import "./index.css";
import { connect } from "react-redux";
import { openRequestModal } from "../../actions/modals";
import { bindActionCreators } from "redux";
import {
  getOlympResults,
  clearOlympData,
  processOlympType,
} from "../../actions/olympiad";
import { withTranslation } from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Skeleton from "@material-ui/lab/Skeleton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";

import Typography from "@material-ui/core/Typography";

class OlympiadPage extends React.Component {
  state = {
    index: 0,
  };
  componentDidMount() {
    this.props.getOlympResults();
  }
  componentWillUnmount() {
    this.props.clearOlympData();
  }

  processDate(item, type) {
    let d = new Date(item);

    const _min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
    const _hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
    const _month =
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    const _date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

    const date = `${_date}.${_month}.${d.getFullYear()}`;
    const dateTime = `${_date}.${_month}.${d.getFullYear()} ${_hours}:${_min}`;
    return type === "champs" ? date : dateTime;
  }

  processTable(type = false) {
    const { olympiad, t } = this.props;
    const reducer = type === "champs" ? olympiad.champions : olympiad.list;
    let data = {};
    for (let item in reducer) {
      if (reducer[item].length) {
        data[`olympiad.${item}`] = reducer[item];
      }
    }
    return (
      <>
        {data &&
          Object.keys(data).map((el, index) => {
            return (
              <Paper className="olympiad_table" key={index}>
                <Toolbar>
                  <Typography variant="h6" id="tableTitle" component="div">
                    {t(el)}
                  </Typography>
                </Toolbar>
                <TableContainer component={Paper}>
                  <Table aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>№</TableCell>
                        <TableCell align="left">
                          {t("olympiad.student")}
                        </TableCell>
                        <TableCell align="left">
                          {t("olympiad.right_stat")}
                        </TableCell>
                        <TableCell align="left">
                          {t("olympiad.speed")}
                        </TableCell>
                        <TableCell align="left">
                          {t("olympiad.group")}
                        </TableCell>
                        <TableCell align="left">{t("olympiad.date")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data[el].map((row, i) => {
                        return (
                          <TableRow key={`${type}.${row.answer_avg_time}`}>
                            <TableCell>
                              {type === "champs" ? i + 1 : row.position}
                            </TableCell>
                            <TableCell align="left">{row.user}</TableCell>
                            <TableCell align="left">
                              {row.answer_correct}
                            </TableCell>
                            <TableCell align="left">
                              {row.answer_avg_time}
                            </TableCell>
                            <TableCell align="left">
                              {row.group.split("|||")[0]}
                            </TableCell>
                            <TableCell align="left">
                              {this.processDate(
                                type === "champs"
                                  ? row.olimp_date
                                  : row.created,
                                type
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            );
          })}
      </>
    );
  }

  render() {
    const { t } = this.props;
    const loading =
      this.props.olympiad &&
      this.props.olympiad.list &&
      Object.keys(this.props.olympiad.list).length;
    const handleChange = (event, value) => {
      this.setState({
        index: value,
      });
    };
    const { index } = this.state;
    return (
      <Fragment>
        <h2 className="container-header"> {t("sidebar.olympiad")} </h2>

        <Tabs value={index} onChange={handleChange} className="olymp_swipe">
          <Tab label={t("sidebar.olympiad")} />
          <Tab label={t("olympiad.champions")} />
        </Tabs>
        <SwipeableViews index={index}>
          <div>
            {!loading ? (
              <>
                <Skeleton
                  className="olympiad_loading"
                  variant="rect"
                  width={"100%"}
                  height={150}
                />
                <Skeleton
                  className="olympiad_loading"
                  variant="rect"
                  width={"100%"}
                  height={150}
                />
                <Skeleton
                  className="olympiad_loading"
                  variant="rect"
                  width={"100%"}
                  height={150}
                />
              </>
            ) : (
              this.processTable()
            )}
          </div>
          <div>
            {!loading ? (
              <>
                <Skeleton
                  className="olympiad_loading"
                  variant="rect"
                  width={"100%"}
                  height={150}
                />
                <Skeleton
                  className="olympiad_loading"
                  variant="rect"
                  width={"100%"}
                  height={150}
                />
                <Skeleton
                  className="olympiad_loading"
                  variant="rect"
                  width={"100%"}
                  height={150}
                />
              </>
            ) : (
              this.processTable("champs")
            )}
          </div>
        </SwipeableViews>
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({ olympiad: state.olympiad }),
  (dispatch) =>
    bindActionCreators(
      { getOlympResults, openRequestModal, clearOlympData, processOlympType },
      dispatch
    )
);

export default withTranslation()(widthConnect(OlympiadPage));
