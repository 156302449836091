import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BRACELETS } from '../../constants/bracelet';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import i18n from 'i18next';
import { useMediaQuery } from '@mui/material';
import usePhoneCheck from '../../hooks/usePhoneCheck';

const franchControlSchema = {
    0: 'ACTIVE_FRANCH',
    1: 'BLOCK_FRANCH',
};

const canBeDisabled = ['add_amount', 'refund'];

const CustomMenuItem = ({
    Action,
    index,
    bracelet,
    handleMenuClose,
    isBraceletButtonActive,
    row,
    outOfMenu,
    franchControl,
}) => {
    if (outOfMenu) {
        const disabled =
            Action.name &&
            canBeDisabled.includes(Action.name) &&
            row.status_payments === 1;
        return (
            <Tooltip
                placement="top"
                title={
                    row.defaultTemplate
                        ? 'Стандартный шаблон. Изменить может только Босс'
                        : Action.title
                }
            >
                <span>
                    <IconButton
                        disabled={disabled}
                        className={`${
                            Action.data === 'CorrectTime' ? 'CorrectTime' : ''
                        }${isBraceletButtonActive && ' bracelet'}${
                            !!bracelet && ` ${bracelet.label}`
                        }${
                            franchControl !== null
                                ? ` ${franchControlSchema[franchControl]}`
                                : ''
                        }`}
                        style={{
                            backgroundColor:
                                (isBraceletButtonActive && bracelet.color) ||
                                '',
                        }}
                        onClick={() => {
                            !row.defaultTemplate &&
                                Action.onClick(
                                    Action['data'] && row[Action['data']]
                                        ? row[Action['data']]
                                        : row.id
                                        ? row.id
                                        : row.title,
                                    row
                                );
                        }}
                    >
                        <Action.Icon
                            fontSize="small"
                            style={{
                                color:
                                    isBraceletButtonActive &&
                                    bracelet.color === '#ffffff'
                                        ? '#000'
                                        : '',
                            }}
                        />
                    </IconButton>
                </span>
            </Tooltip>
        );
    }
    return (
        <MenuItem
            key={index}
            onClick={() => {
                !row.defaultTemplate &&
                    Action.onClick(
                        Action['data'] && row[Action['data']]
                            ? row[Action['data']]
                            : row.id
                            ? row.id
                            : row.title,
                        row
                    );
                handleMenuClose();
            }}
            className={`${Action.data === 'CorrectTime' ? 'CorrectTime' : ''}${
                isBraceletButtonActive && ' bracelet'
            }${!!bracelet && ` ${bracelet.label}`}${
                franchControl !== null
                    ? ` ${franchControlSchema[franchControl]}`
                    : ''
            }`}
            style={{
                backgroundColor:
                    (isBraceletButtonActive && bracelet.color) || 'inherit',
            }}
        >
            <ListItemIcon>
                <Action.Icon
                    fontSize="small"
                    style={{
                        color:
                            isBraceletButtonActive &&
                            bracelet.color === '#ffffff'
                                ? '#000'
                                : '',
                    }}
                />
            </ListItemIcon>
            <ListItemText>
                <Typography variant="body2" color="text.secondary">
                    {row.defaultTemplate
                        ? 'Стандартный шаблон. Изменить может только Босс'
                        : Action.title}
                </Typography>
            </ListItemText>
        </MenuItem>
    );
};

const MenuMap = ({ actions, row, handleMenuClose, outOfMenu = false }) => {
    return (
        <React.Fragment>
            {actions
                .filter((item) =>
                    outOfMenu ? item.outOfMenu : !item.outOfMenu
                )
                .map((Action, index) => {
                    let bracelet;
                    const isBraceletButtonActive =
                        Action.bracelet && row && row.bracelet;
                    if (isBraceletButtonActive) {
                        try {
                            bracelet = BRACELETS.find(
                                (el) => el.label === row.bracelet
                            );
                        } catch (e) {}
                    }
                    return (
                        <CustomMenuItem
                            key={index}
                            Action={Action}
                            bracelet={bracelet}
                            index={index}
                            isBraceletButtonActive={isBraceletButtonActive}
                            handleMenuClose={handleMenuClose}
                            row={row}
                            outOfMenu={outOfMenu}
                            franchControl={
                                Action.franchControl ? row.status : null
                            }
                        />
                    );
                })}
        </React.Fragment>
    );
};

const MenuLayout = ({
    children,
    handleMenuOpen,
    anchorEl,
    handleMenuClose,
}) => {
    return (
        <React.Fragment>
            <IconButton onClick={handleMenuOpen}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {children}
            </Menu>
        </React.Fragment>
    );
};

const BodyActionButtons = ({ actions, row, classes }) => {
    const mobile = usePhoneCheck()

    const [isMenuVisible, setMenuVisible] = useState(false);
    const menuHidden = useMemo(() => {
        try {
            return actions.filter((el) => !el.outOfMenu).length === 0;
        } catch (e) {
            console.log(e);
        }
    }, [actions]);

    const countMenuVisible = () => {
        if (actions.length !== 0) {
            for (const item of actions) {
                if (item.outOfMenu) {
                    setMenuVisible(true);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        countMenuVisible();
    }, []);

    // State to manage the anchor element of the popup menu
    const [anchorEl, setAnchorEl] = useState(null);

    // Function to open the popup menu
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Function to close the popup menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            {!!actions.length && (
                <TableCell
                    component={mobile ? 'div' : 'td'}
                    align={mobile ? 'left' : 'right'}
                    className={`${
                        mobile ? 'mobile_actions ' : ''
                    }not_disabled ${
                        row.defaultTemplate
                            ? 'AsyncTabledisabled ' + classes.actions
                            : classes.actions
                    }`}
                >
                    {isMenuVisible && (
                        <MenuMap
                            handleMenuClose={handleMenuClose}
                            row={row}
                            actions={actions}
                            outOfMenu
                        />
                    )}
                    {!menuHidden ? (
                        <MenuLayout
                            handleMenuClose={handleMenuClose}
                            handleMenuOpen={handleMenuOpen}
                            anchorEl={anchorEl}
                        >
                            <MenuMap
                                handleMenuClose={handleMenuClose}
                                row={row}
                                actions={actions}
                            />
                        </MenuLayout>
                    ) : null}
                </TableCell>
            )}
        </React.Fragment>
    );
};

export default BodyActionButtons;
