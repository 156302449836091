import { ApiConnector, GET, POST } from './request.service';

export default (() => {
    const API = ApiConnector('/payments');
    return {
        PaymentsList: async (data, query) =>
            await API(GET, '', false, query).call(data),
        StudentFromListList: async (data, query) =>
            await API(GET, '', false, query).call(data),
        SendPayment: async (data, query) =>
            await API(POST, '/replenishment', false, query).call(data),
        SendPaymentFranch: async (data, query) =>
            await API(POST, '/franch', false, query).call(data),
        ReturnPayment: async (data, query) =>
            await API(POST, '/return', false, query).call(data),
        PaymentHistory: async (data, query) =>
            await API(GET, '/history', false, query).call(data),
        StudentsPayments: async (data, query) =>
            await API(GET, '', false, query).call(data),
        CreateBill: async (data, query) =>
            await API(POST, '/replenishment/bank', false, query).call(data),
        DebtList: async (data, query) =>
            await API(GET, '/franch/debtreliev', false, query).call(data),
        DebtListArchive: async (data, query) =>
            await API(GET, '/franch/archive', false, query).call(data),
        DebtListGraduate: async (data, query) =>
            await API(GET, '/franch/graduate', false, query).call(data),
        ProcessDebt: async (data) =>
            await API(POST, '/franch/debtreliev', false, false).call(data),
        ProcessDebtArchive: async (data) =>
            await API(POST, '/franch/archive', false, false).call(data),
        ProcessDebtGraduate: async (data) =>
            await API(POST, '/franch/graduate', false, false).call(data),
        GetCountedResult: async () =>
            await API(GET, '/info/count', false, false).call(),
        getPaymentsInfoResult: async () =>
            await API(GET, '/franch/paymentsinfo', false, false).call(),
        SendMailToAllStudents: async (data) =>
            await API(POST, '/sendbill/group', false, false).call(data),
        CreateSoloBill: async () =>
            API(POST, '/replenishment/me', false, false).call(),
        ExperimentPayments: async (query) =>
            API(GET, '/experiment', false, query).call(),
        UnblockStudent: async (data) =>
            API(POST, '/unblock', false, false).call(data),
    };
})();
