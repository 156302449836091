import store from './../store';
import API_ROOT, { getURL } from './../constants/urlAPI';
import { OLD_API } from '../constants/oldApiUrl';
import Auth from './auth.service';
import { resume, emulation as Emulation } from './../actions/user';
import { getError, getSuccess, getErrorTranslate } from '../actions/toast';
import Types from '../constants/user';
export const GET = (...args) => makeRequest('GET', ...args);
export const POST = (...args) => makeRequest('POST', ...args);
export const PUT = (...args) => makeRequest('PUT', ...args);
export const DELETE = (...args) => makeRequest('DELETE', ...args);

const formData = (object) =>
    Object.keys(object).reduce((form, key) => {
        form.append(key, object[key]);
        return form;
    }, new FormData());

async function makeRequest(
    method,
    endpoint,
    rawData = false,
    multipart = false,
    sendEmulation = true,
    oldApi = false,
    noErrorMessage = false,
    responseType = 'json'
) {
    const { access_token, emulation } = store.getState().user;
    const headers = oldApi
        ? new Headers({
              'Content-Type': 'application/x-www-form-urlencoded',
              Accept: 'application/json',
          })
        : new Headers({
              'Content-Type': 'application/json',
          });
    if (access_token) {
        headers.append('authorization', `Basic ${access_token}`);
    }
    if (sendEmulation && emulation && emulation.length) {
        headers.append(
            'emulation',
            `Basic ${emulation[emulation.length - 1].token}`
        );
    }

    const options = {
        method,
        headers,
        mode: 'cors',
        cache: 'default',
    };
    const body =
        !!rawData &&
        (multipart
            ? formData(rawData)
            : oldApi
            ? makeToQuery(rawData, true)
            : JSON.stringify(rawData));

    if (!!body) {
        Object.assign(options, { body });
    }
    const { user } = store.getState();
    const id_admin = user.info && user.info.role_id;

    const APIURL = window.location.pathname.includes('/hidden')
        ? getURL()
        : API_ROOT;

    const request = oldApi
        ? new Request(oldApi, options)
        : new Request(APIURL + endpoint, options);

    if (
        id_admin &&
        !endpoint.includes('auth') &&
        id_admin === 10 &&
        method !== 'GET'
    ) {
        return getErrorTranslate('error.no_access_to_fetch');
    }
    try {
        const data = await fetch(request);
        let json;
        if (responseType === 'blob') {
            return await data;
        } else {
            json = await data.json();
        }

        if (oldApi) {
            if (json.info === 'Redirect to new api') {
                return true;
            } else if (!Object.keys(json).length) {
                return true;
            } else if (!json.data.length) {
                return true;
            } else if (
                json.data[0].role === 'boss' ||
                json.data[0].role === 'superadmin'
            ) {
                return true;
            } else if (json.data.length >= 1) {
                document.location.href = 'https://p.soroban.ua';
                return false;
            }
            return true;
        }
        if (json.status) {
            if (
                method !== 'GET' &&
                !endpoint.includes('auth') &&
                !endpoint.includes('correct-schema-hw')
            ) {
                getSuccess();
            }
            if (json.payload.successCode) {
                getSuccess();
            }
            return json.payload;
        } else if (json.error === 'Token is outdated') {
            const { user } = store.getState();
            const { refresh_token, isRefreshTokenUpdateProcess } = user;
            if (!isRefreshTokenUpdateProcess) {
                store.dispatch({
                    type: Types.RESUME,
                    user: {
                        ...user,
                        isRefreshTokenUpdateProcess: true,
                    },
                });
                return Auth.Resume({ refresh_token }).then(async (response) => {
                    resume(response)(store.dispatch, store.getState);
                    return await makeRequest(
                        method,
                        endpoint,
                        rawData,
                        multipart
                    );
                });
            } else {
                return new Promise(async (resolve) => {
                    const unsubscribe = store.subscribe(async () => {
                        const { isRefreshTokenUpdateProcess } =
                            store.getState().user;
                        if (!isRefreshTokenUpdateProcess) {
                            unsubscribe();
                            const newResponse = await makeRequest(
                                method,
                                endpoint,
                                rawData,
                                multipart
                            );
                            resolve(newResponse);
                        }
                    });
                });
            }
        } else if (json.error === 'Emulation token is outdated') {
            const { user } = store.getState();
            const { isEmulationTokenUpdateProcess } = user;
            if (!isEmulationTokenUpdateProcess) {
                store.dispatch({
                    type: Types.RESUME,
                    user: {
                        ...user,
                        isEmulationTokenUpdateProcess: true,
                    },
                });
                await Emulation({
                    id: user.emulation[emulation.length - 1].user_id,
                })(store.dispatch, store.getState);
                return await makeRequest(method, endpoint, rawData, multipart);
            } else {
                return new Promise(async (resolve) => {
                    const unsubscribe = store.subscribe(async () => {
                        const {
                            isEmulationTokenUpdateProcess,
                            isRefreshTokenUpdateProcess,
                        } = store.getState().user;
                        if (
                            !isEmulationTokenUpdateProcess &&
                            !isRefreshTokenUpdateProcess
                        ) {
                            unsubscribe();
                            const newResponse = await makeRequest(
                                method,
                                endpoint,
                                rawData,
                                multipart
                            );
                            resolve(newResponse);
                        }
                    });
                });
            }
        } else {
            const error = new Error(json.error);
            error.name = 'API';
            error.number = data.status;
            error.url = data.url;
            // getError({ ...error });
            throw error;
        }
    } catch ({ message, name, number, url }) {
        if (!!oldApi) {
            return true;
        }

        if (number === 403) {
            if (message === 'Token has been changed') {
                // getError({ message, name, number, url });
                localStorage.setItem('globalStore', JSON.stringify({}));
                document.location = `${document.location.origin}/login`;
                return;
            } else {
                if (!noErrorMessage) {
                    getError({ message, name, number, url });
                }
                return Promise.reject({ message, name, number, url });
            }
        }
        if (number === 401) {
            if (message === 'Token recovery is not subject to') {
                localStorage.setItem('globalStore', JSON.stringify({}));
                document.location.reload(true);
                return;
            }
        }
        if (!noErrorMessage) {
            getError({ message, name, number, url });
        }
        return Promise.reject({ message, name, number, url });
    }
}

const makeToQuery = (query, noGet = false) => {
    if (!query) return '';
    var str = [];
    for (let p in query)
        if (query.hasOwnProperty(p)) {
            str.push(
                encodeURIComponent(p) + '=' + encodeURIComponent(query[p])
            );
        }
    if (noGet) return str.join('&');
    return '?' + str.join('&');
};

export const ApiConnector = (prefix = '') => {
    return (
        callback,
        endpoint,
        multipart = false,
        query = false,
        sendEmulation = true,
        oldApi = false
    ) => ({
        async call(
            body = false,
            noErrorMessage = false,
            responseType = 'json'
        ) {
            if (oldApi) {
                oldApi = OLD_API + endpoint;
            }
            try {
                return await callback(
                    ...[
                        prefix + endpoint + makeToQuery(query),
                        body,
                        multipart,
                        sendEmulation,
                        oldApi,
                        noErrorMessage,
                        responseType,
                    ]
                );
            } catch (message) {
                if (message && noErrorMessage) {
                    return Promise.reject(message);
                }
            }
        },
    });
};
