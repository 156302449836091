import Types from "../constants/olympiad";
import OlympiadService from "../services/olympiad.service";

export function getOlympResults() {
  return async (dispatch, getState) => {
    const res = await OlympiadService.OlympiadList();
    const champions = await OlympiadService.ChampionsList();
    dispatch({
      type: Types.OLYMP,
      olympiad: {
        list: res,
        champions
      },
    });
  };
}


export function processOlympType(item) {
  return async (dispatch, getState) => {
    const { olympiad } = getState();
    dispatch({
      type: Types.OLYMP,
      olympiad: {
        ...olympiad,
        type: item
      },
    });
  }
}

export function clearOlympData() {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.OLYMP,
      olympiad: {
        list: null,
        champions: null
      },
    });
  };
}
