import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BankTransactionsService from '../../services/bank-transactions.service';
import GroupsService from '../../services/groups.service';
import { setStudentsBalances } from '../../actions/bank';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import base64url from 'base64url';
import store from '../../store';
import usePhoneCheck from '../../hooks/usePhoneCheck';
import BankMobileBody from '../../components/ComponentTable/Table/BankMobileBody';

import {
    Stack,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Toolbar,
    Typography,
    Paper,
    Checkbox,
    IconButton,
    Tooltip,
    TextField,
    Autocomplete,
    CircularProgress,
    Button,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { showCurrentStatus } from '../../actions/user';
import BackButton from '../../components/BalanceComponent/BackButton';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { toast } from 'react-toastify';


const headCells = [
    {
        id: 'rowId',
        numeric: false,
        disablePadding: false,
        label: 'id',
    },
    {
        id: 'surname',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.surname',
    },
    {
        id: 'name',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.name',
    },
    {
        id: 'balance',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.balance',
    },
    {
        id: 'teacher',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.teacher',
    },
    {
        id: 'group',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.group',
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: false,
        label: 'bankStudentsPage.status',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'bankStudentsPage.quantity',
    },
    {
        id: 'bankAccount',
        numeric: true,
        disablePadding: false,
        label: 'bankStudentsPage.bankAccount',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected } = props;
    const { t } = useTranslation();
    const roleId = useSelector((state) => state.user.info.role_id);
    const { emulation } = store.getState().user;

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>
                {headCells.map(
                    (headCell) =>
                        // (roleId !== 4 || headCell.id !== 'teacher') && (
                        (((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length)) || headCell.id !== 'teacher') && (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                padding={
                                    headCell.disablePadding ? 'none' : 'normal'
                                }
                            >
                                <Typography>{t(headCell.label)}</Typography>
                            </TableCell>
                        )
                )}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
};

function EnhancedTableToolbar(props) {
    const { numSelected, onSuccessClick, loading } = props;
    const { t } = useTranslation();

    return (
        <Toolbar
            sx={{
                mt: 2,
                mb: 2,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {numSelected} {t('bankStudentsPage.selectedStudents')}
                </Typography>
            ) : (
                <></>
            )}
            {numSelected > 0 ? (
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    {loading ? <CircularProgress size={20} /> : null}
                    <Tooltip title={t('bankStudentsPage.tooltipTitle')}>
                        <IconButton onClick={onSuccessClick} disabled={loading}>
                            <DoneOutlineIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            ) : (
                <></>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSuccessClick: PropTypes.func.isRequired,
};

function getRowData(data, groupTitles) {
    return data
        ? data.map((item) => ({
              error: false,
              rowId: item.student_id,
              name: item.student_name.split(' ').pop() || '',
              surname: item.student_name.split(' ')[0] || '',
              group: groupTitles[item.course_id] || '',
              balance: item.student_balance || '',
              studentBalances: item.students_balances || '',
              quantity: 1,
              teacher: item.teacher_name || '',
              status: item.student_status,
          }))
        : [];
};

export default function BankStudentsPage() {
    const [selected, setSelected] = useState([]);
    const [student, setStudent] = useState('');
    const [group, setGroup] = useState('');
    const [teacher, setTeacher] = useState('');
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [groupTitles, setGroupTitles] = useState({});
    const [errorStates, setErrorStates] = useState({});

    const { emulation } = store.getState().user;
    const history = useHistory();
    const roleId = useSelector((state) => state.user.info.role_id);
    const { t, i18n } = useTranslation();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;
    const studentsBalances = user.customer.studentsBalances;
    const mobile = usePhoneCheck();
   
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Отримати інформацію про группи за id
                const response = await GroupsService.GroupsList(false, {
                    role_id: +roleId,
                    page: 0,
                    size: 9999,
                    orderBy: 'lesson_day',
                    order: 'asc',
                });

                const titlesMap = response.list.reduce((map, group) => {
                    map[group.id] = group.title;
                    return map;
                }, {});
                setGroupTitles(titlesMap);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [roleId]);

    const studentsBalancesInfo = useMemo(() => {
        // if (Object.keys(groupTitles).length === 0) return [];
        return getRowData(user.customer.studentsBalances, groupTitles);
    }, [user, groupTitles]);

    useEffect(() => {
        setRows(studentsBalancesInfo);
    }, [studentsBalancesInfo]);

    const [rows, setRows] = useState(studentsBalancesInfo);

    const dispatch = useDispatch();

    const fetchStudentsBalances = async () => {
        await dispatch(setStudentsBalances());
    };

    useEffect(() => {
        fetchStudentsBalances();
    }, []);

    const handleQuantityChange = (id, value) => {
        // Знаходимо індекс рядка з вказаним id
        const rowIndex = rows.findIndex((row) => row.rowId === id);
        // Створюємо новий екземпляр масиву rows, щоб уникнути мутації стану безпосередньо
        const newRows = [...rows];
        // Оновлюємо значення 'quantity' для конкретного рядка
        newRows[rowIndex] = {
            ...newRows[rowIndex],
            quantity: parseFloat(value) || '',
        };
        // Оновлюємо значення 'quantity' для конкретного рядка
        setRows(newRows);

        // Оновлюємо стан помилок
        setErrorStates((prevState) => ({
            ...prevState,
            [id]: value === '',
        }));
    };

    const renderQuantityInput = (id, quantity, onChange) => (
        <TextField
            id={`quantity-input-${id}`}
            value={quantity}
            type="number"
            color="warning"
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            required
            error={isSelected(id) && (quantity === '' || quantity === null)}
            helperText={isSelected(id) && (quantity === '' || quantity === null) ? t('bankStudentsPage.fillField') : ''}
            onChange={(e) => {
                let value = e.target.value;
                value = value.replace(',', '.');

                const regex = /^\d*\.?\d{0,2}$/;
                if (!regex.test(value)) {
                    value = value.slice(0, -1);
                }
                onChange(id, value);
            }}
            onKeyDown={(e) => {
                if (['-', '+', '=', '*', '/', '.'].includes(e.key)) {
                    e.preventDefault();
                }
            }}
            inputProps={{
                min: 0,
                step: 0.01,
            }}
            style={{ width: '70px' }}
        />
    );

    const handleSelectAllClick = (event) => {
        if (loading) return;
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.rowId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        if (loading) return;

        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = [...selected, id];
        } else if (selectedIndex >= 0) {
            newSelected = selected.filter((itemId) => itemId !== id);
        }

        setSelected(newSelected);

        setErrorStates((prevState) => ({
            ...prevState,
            [id]: false, // Очищення стану помилки при кліку на чекбокс
        }));
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    async function handleSuccessClick() {
        
        // Оберемо транзакції з 0 баланс, якщо такі є виходимо з функції
        const hasEmptyQuantity = rows
            .filter((row) => isSelected(row.rowId))
            .some(row => !row.quantity);

        if (hasEmptyQuantity) {
            toast.error(t('bankStudentsPage.hasEmptyQuantity'));
            return;
        };
          
    // Відфільтровуємо транзакції для підтвердження    
        const data = rows
            .filter((row) => isSelected(row.rowId))
            .map((el) => ({
                amount: Number(el.quantity),
                customerId: Number(el.rowId),
                note: '',
            }));

        const totalAmount = data.reduce(
            (accumulator, user) => accumulator + user.amount,
            0
        );
        if (totalAmount <= balance) {
            await Promise.all(
                data.map(async (item) => {
                    try {
                        await BankTransactionsService.TransactionById(item);
                    } catch (error) {
                        console.log(error);
                    }
                })
            );
        } else {
            toast.error(t('sorokoinsForHomework.notEnoughMoney'));
        }

        setSelected([]);
    }

    // Фільтрація
    const handleFilterChange = (
        newStudent,
        newGroup,
        newTeacher,
        newStatus
    ) => {
        if (loading) return;

        let filteredRows = studentsBalancesInfo;

        if (newStudent) {
            filteredRows = filteredRows.filter(
                (row) => row.surname === newStudent
            );
        }
        if (newGroup) {
            filteredRows = filteredRows.filter((row) => {
                if (typeof row.group === 'object') {
                    return Object.values(row.group).find(value => value !== null && value !== undefined && value !== '') === newGroup
                }
                return row.group === newGroup
            });
        }
        if (newTeacher) {
            filteredRows = filteredRows.filter(
                (row) => row.teacher === newTeacher
            );
        }
        if (newStatus) {
            filteredRows = filteredRows.filter(
                (row) => showCurrentStatus(row.status, {t,i18n,}) === newStatus
            );
        }

        setRows(filteredRows);
    };

    const handleBankAccountClick = async (id) => {
        history.push(`/students/bank/${id}/students`);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <BackButton />
            <Paper sx={{ width: '100%', mb: 2, p: 3 }}>
                <Stack direction={mobile ? "column" : "row"} justifyContent="flex-start" spacing={2}>
                    <Autocomplete
                        id="controllable-states-demo"
                        options={rows
                            .map((option) => option.surname)
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newStudent) =>
                            handleFilterChange(
                                newStudent,
                                group,
                                teacher,
                                status
                            )
                        }
                        inputValue={student}
                        onInputChange={(event, newInputValue) => {
                            setStudent(newInputValue);
                        }}
                        sx={{ width: 260, marginBottom: '10px' }}
                        renderInput={(params) => (
                            <TextField {...params} label={t('bankStudentsPage.studentSurname')} />
                        )}
                    />
                    <Autocomplete
                        id="controllable-states-demo"
                        options={rows
                            .map((option) => {
                                if (typeof option.group === 'object') {
                                    return Object.values(option.group).find(value => value !== null && value !== undefined && value !== '') || ''
                                }
                                return option.group || '';
                            })
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newGroup) =>
                            handleFilterChange(
                                student,
                                newGroup,
                                teacher,
                                status
                            )
                        }
                        inputValue={group}
                        onInputChange={(event, newInputValue) => {
                            setGroup(newInputValue);
                        }}
                        sx={{ width: 260, marginBottom: '10px' }}
                        renderInput={(params) => (
                            <TextField {...params} label={t('bankStudentsPage.group')} />
                        )}
                    />
                    {/* {roleId === 3 && ( */}
                    {((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length)) && (
                        <Autocomplete
                            id="controllable-states-demo"
                            options={rows
                                .map((option) => option.teacher)
                                .filter(
                                    (el, index, arr) =>
                                        arr.indexOf(el) === index
                                )}
                            onChange={(event, newTeacher) =>
                                handleFilterChange(
                                    student,
                                    group,
                                    newTeacher,
                                    status
                                )
                            }
                            inputValue={teacher}
                            onInputChange={(event, newInputValue) => {
                                setTeacher(newInputValue);
                            }}
                            sx={{ width: 260, marginBottom: '10px' }}
                            renderInput={(params) => (
                                <TextField {...params} label={t('bankStudentsPage.teacher')} />
                            )}
                        />
                    )}
                    <Autocomplete
                        id="controllable-states-demo"
                        options={rows
                            .map((option) => showCurrentStatus(option.status, { t, i18n, }))
                            .filter(
                                (el, index, arr) => arr.indexOf(el) === index
                            )}
                        onChange={(event, newStatus) =>
                            handleFilterChange(
                                student,
                                group,
                                teacher,
                                newStatus
                            )
                        }
                        inputValue={status}
                        onInputChange={(event, newInputValue) => {
                            setStatus(newInputValue);
                        }}
                        sx={{ width: 260, marginBottom: '10px' }}
                        renderInput={(params) => (
                            <TextField {...params} label={t('bankStudentsPage.status')} />
                        )}
                    />
                </Stack>

                {/* Передаємо кількість обраних рядків та функцію видалення в панель інструментів */}
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    onSuccessClick={handleSuccessClick}
                    loading={loading}
                />
                {mobile ? (
                    <BankMobileBody
                        rows={rows}
                        headCells={headCells}
                        isSelected={isSelected}
                        handleSelectAllClick={handleSelectAllClick}
                        handleQuantityChange={handleQuantityChange}
                        handleClick={handleClick}
                        renderQuantityInput={renderQuantityInput}
                        showCurrentStatus={showCurrentStatus}
                        handleBankAccountClick={handleBankAccountClick}
                        roleId={roleId}
                        emulation={emulation}
                    />
                ) : (
                    <TableContainer style={{ maxHeight: '59vh' }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        // size={dense ? 'small' : 'medium'}
                        >
                            <EnhancedTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {rows.map((row, index) => {
                                    const isItemSelected = isSelected(row.rowId);
                                    const labelId = ` enhanced - table - checkbox - ${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.rowId}
                                            selected={isItemSelected}
                                            sx={{ cursor: 'pointer' }}
                                            style={{
                                                transition: 'all 0.2s',
                                                backgroundColor: row.error
                                                    ? '#ff000033'
                                                    : 'transparent',
                                            }}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    onClick={(event) =>
                                                        handleClick(
                                                            event,
                                                            row.rowId
                                                        )
                                                    }
                                                    checked={isItemSelected}
                                                    inputProps={{
                                                        'aria-labelledby': labelId,
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.rowId}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.surname}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.balance}
                                            </TableCell>
                                            {/* {roleId === 3 && ( */}
                                            {((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length)) && (
                                                <TableCell align="left">
                                                    {row.teacher}
                                                </TableCell>
                                            )}
                                            <TableCell align="left">
                                                {
                                                    typeof row.group === 'object'
                                                        ? (Object.values(row.group).find(value => value !== null && value !== undefined && value !== '') || '')
                                                        : (row.group || '')
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                {showCurrentStatus(row.status, {
                                                    t,
                                                    i18n,
                                                })}
                                            </TableCell>

                                            <TableCell align="left">
                                                {renderQuantityInput(
                                                    row.rowId,
                                                    row.quantity,
                                                    handleQuantityChange
                                                )}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Tooltip
                                                    title="SOROBANK"
                                                    placement="bottom"
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            handleBankAccountClick(
                                                                row.rowId
                                                            )
                                                        }
                                                        style={{
                                                            height: '40px',
                                                            borderRadius: '20px',
                                                            fontSize: '14px',
                                                            color: '#58a315'
                                                        }}
                                                        aria-label="SOROBANK"
                                                        type="button"
                                                        startIcon={
                                                            <AccountBalanceIcon
                                                                style={{ color: '#58a315' }}
                                                            />
                                                        }
                                                    >
                                                        SOROBANK
                                                    </Button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Paper>
        </Box>
    );
}
