import Types from '../constants/groups';
import { closeRequestModal, openRequestModal } from './modals';
import { reloadTable } from './AsyncTable';

import GroupsService from '../services/groups.service';
import UsersService from '../services/users.service';
import CitiesService from '../services/cities.service';
import { getError } from './toast';
import SCHEMAS from '../constants/modalSchemas';
import i18next from 'i18next';

function updateEqualizerState(data) {
    return async (dispatch, getState) => {
        const { single } = getState().groups;
        await editGroup({ ...single, ...data }, true)(dispatch, getState);
        setTimeout(() => {
            getSingleGroup(single.id)(dispatch, getState);
        }, 300);
    };
}

function getOfficeGroups(data) {
    return async (dispatch) => {
        dispatch({
            type: Types.GROUPS,
            groups: {
                loading: true,
            },
        });
        const res = await GroupsService.GetGroupsByOffice(data);
        if (res) {
            dispatch({
                type: Types.GROUPS,
                groups: {
                    officeGroups: res.list,
                    loading: false,
                },
            });
        }
    };
}

function setLastTopic(item) {
    return (dispatch) => {
        dispatch({
            type: Types.GROUPS,
            groups: {
                lastTopic: item,
            },
        });
    };
}

function setExitBool(item) {
    return (dispatch) => {
        dispatch({
            type: Types.GROUPS,
            groups: {
                acceptExitHwChange: item,
            },
        });
    };
}

function clearOfficeGroups() {
    return (dispatch) => {
        dispatch({
            type: Types.GROUPS,
            groups: {
                officeGroups: null,
                loading: false,
            },
        });
    };
}

function getGroupsHistory(...args) {
    return async () => {
        try {
            return await GroupsService.getGroupHistory(...args);
        } catch (e) {
            getError(e);
        }
    };
}

function getGroupHistoryHw(...args) {
    return async () => {
        try {
            return await GroupsService.getGroupHistoryHw(...args);
        } catch (e) {
            getError(e);
        }
    };
}

function getGroups(...args) {
    return async (dispatch) => {
        try {
            const response = await GroupsService.GroupsList(...args);
            dispatch({
                type: Types.GROUPS,
                groups: {
                    ...response,
                },
            });
        } catch (e) {
            getError(e);
        }
    };
}

function getStudentsFromGroup(...args) {
    return async () => {
        try {
            return await GroupsService.getPupilsOfGroups(...args);
        } catch (e) {
            getError(e);
        }
    };
}

function initGroupHw(...args) {
    return async (dispatch, getState) => {
        const time = parseInt(args[0].time);
        if (time < 0 || time < 100) return getError('Invalid data passed');
        try {
            await GroupsService.InitGroupHW(...args);
            closeRequestModal()(dispatch, getState);
            await reloadTable()(dispatch, getState);
        } catch (e) {
            getError(e);
        }
    };
}

async function newEqResetHw(args) {
    return await GroupsService.InitGroupHW(args);
}

function getTeachersFromSelect() {
    return async function (dispatch, getState) {
        try {
            const { groups, user } = getState();

            const response = await UsersService.UsersList(false, {
                role_id:
                    user && user.emulation && user.emulation.length
                        ? +user.emulation[user.emulation.length - 1].info
                              .role_id + 1
                        : +user.info.role_id + 1,
                size: 9999,
            });
            dispatch({
                type: Types.GROUPS,
                groups: {
                    teacher_select: {
                        teacher: response.list.sort((a, b) =>
                            a.last_name > b.last_name ? 1 : -1
                        ),
                    },
                },
            });
            return response;
        } catch (e) {
            console.log(e);
        }
    };
}

function getTeachersForSupport() {
    return async function (dispatch, getState) {
        try {
            const response = await UsersService.UsersList(false, {
                role_id: 4,
                size: 9999,
            });
            dispatch({
                type: Types.GROUPS,
                groups: {
                    teacher_select: {
                        teacher: response.list.sort((a, b) =>
                            a.last_name > b.last_name ? 1 : -1
                        ),
                    },
                },
            });
            return response;
        } catch (e) {
            console.log(e);
        }
    };
}

function clearTeacherGroups() {
    return async function (dispatch) {
        try {
            dispatch({
                type: Types.GROUPS,
                groups: {
                    teacher_select: {
                        teacher: null,
                    },
                },
            });
        } catch (e) {
            console.log(e);
        }
    };
}

function getGroupsFromSelect(...args) {
    return async function (dispatch) {
        try {
            delete args.id;
            const res = await GroupsService.GroupsList(...args);
            const response = res.list.filter((el) => el.id !== args.id);
            dispatch({
                type: Types.GROUPS,
                groups: {
                    select: {
                        list: response,
                    },
                },
            });
            return response;
        } catch (e) {
            console.log(e);
        }
    };
}

function addGroup(...args) {
    return async (dispatch, getState) => {
        closeRequestModal()(dispatch, getState);
        await GroupsService.AddGroup(...args);
        await reloadTable()(dispatch, getState);
    };
}

function editGroup(fields, no_reload = false) {
    return async (dispatch, getState) => {
        const args = JSON.parse(JSON.stringify(fields));
        const group = await getSingleGroup(args.id)(dispatch);
        if(+group.price === +args.price){
            delete args.price;
        }
        args.school_id = +args.geo_school_id;

        if (group.course_type !== '3' && args.course_type === '3') {
            closeRequestModal()(dispatch, getState);
            setTimeout(() => {
                openRequestModal({
                    opened: true,
                    data: {
                        header: i18next.t('HWSettings.remove_hw'),
                        modaltype: 'removeHWModal',
                        course_id: args.id,
                        isChangeToLi: true,
                        updateGroupData: args,
                    },
                })(dispatch, getState);
            }, 200);
            return;
        }
        if (group.course_type === '1' && args.course_type === '0') {
            // Group Activation
            closeRequestModal()(dispatch, getState);
            setTimeout(() => {
                openRequestModal({
                    opened: true,
                    data: {
                        groupToSave: args,
                        schema: SCHEMAS.groupActivationModal,
                        header: i18next.t('groups.group_activation'),
                        modaltype: 'GroupActivationModal',
                    },
                })(dispatch, getState);
            }, 200);
            return;
        }
        closeRequestModal()(dispatch, getState);
        await GroupsService.EditGroup(args);
        if (!no_reload) reloadTable()(dispatch, getState);
    };
}

function getSingleGroup(args) {
    return async (dispatch) => {
        const res = await GroupsService.SingleGroup(false, { course_id: args });
        dispatch({
            type: Types.GROUPS,
            groups: {
                single: res,
            },
        });
        return res;
    };
}

function clearSingleGroup() {
    return async (dispatch) => {
        dispatch({
            type: Types.GROUPS,
            groups: {
                single: null,
            },
        });
    };
}

function deleteGroup(...args) {
    return (dispatch, getState) => {
        closeRequestModal()(dispatch, getState);
        GroupsService.DeleteGroup(...args).then((response) => {
            dispatch({
                type: Types.DELETEGROUP,
            });
            if (response) {
                reloadTable()(dispatch, getState);
            }
        });
    };
}

function getListToPlate(...args) {
    return (dispatch) => {
        CitiesService.CitiesList(...args).then((response) => {
            dispatch({
                type: Types.PLATELIST,
                cities: {
                    list: response.list,
                },
            });
        });
    };
}

function filterData(filter_data) {
    return (dispatch) => {
        dispatch({
            type: Types.FILTRATION,
            groups: {
                filtration: {
                    ...groups.filtration,
                    teacher: filter_data.teacher || null,
                    city: filter_data.city || null,
                    office: filter_data.office || null,
                },
            },
        });
    };
}

export {
    getTeachersForSupport,
    clearTeacherGroups,
    updateEqualizerState,
    getGroups,
    filterData,
    addGroup,
    editGroup,
    deleteGroup,
    getSingleGroup,
    getListToPlate,
    getGroupsFromSelect,
    getTeachersFromSelect,
    initGroupHw,
    getStudentsFromGroup,
    getGroupsHistory,
    getGroupHistoryHw,
    getOfficeGroups,
    clearOfficeGroups,
    setLastTopic,
    setExitBool,
    newEqResetHw,
    clearSingleGroup,
};
