import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from './../../store';
import { openRequestModal } from '../../actions/modals';
import { setProductsAllFranch } from '../../actions/bank';
import BankProductsService from '../../services/bank-products.service';
import { useTranslation } from 'react-i18next';

import {
    Stack,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

const headCells = [
    {
        id: 'displayOrder',
        numeric: false,
        disablePadding: false,
        label: '№',
        align: 'left',
    },
    {
        id: 'image',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.image',
        align: 'left',
    },
    {
        id: 'productName',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.productName',
        align: 'left',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'storefrontComponent.quantity',
        align: 'left',
    },
    {
        id: 'price',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.price',
        align: 'left',
    },
    {
        id: 'vip',
        numeric: false,
        disablePadding: false,
        label: 'VIP',
        align: 'left',
    },    
    {
        id: 'options',
        numeric: false,
        disablePadding: false,
        label: 'storefrontComponent.options',
        align: 'right',
    },
];

function EnhancedTableHead() {
    const { t } = useTranslation();
    const { emulation } = store.getState().user;
    const roleId = useSelector((state) => state.user.info.role_id);

    return (
        <TableHead>
            <TableRow>
                {headCells
                    .filter(
                        (_, index) =>
                            (roleId === 3 && !emulation.length) ||
                            (index !== 0 && index !== headCells.length - 1)
                    )
                    .map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align}
                            padding={
                                headCell.disablePadding ? 'none' : 'normal'
                            }
                        >
                            <Typography variant={'body2'}>
                                {t(headCell.label)}
                            </Typography>
                        </TableCell>
                    ))}
            </TableRow>
        </TableHead>
    );
}

export default function StorefrontComponent() {
    const [hiddenRows, setHiddenRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const roleId = useSelector((state) => state.user.info.role_id);
    const dispatch = useDispatch();
    const { emulation } = store.getState().user;
    const user = useSelector((state) => state.bank);
    const sortedRows = rows.slice().toSorted((a, b) => a.promo - b.promo);
    const { t } = useTranslation();


    // отримати всі товари франча
    useEffect(() => {
        const getProductsAllFranch = () => {
            if (roleId === 3 && !emulation.length) {
                const productsAllFranch = user.market;
                setRows(productsAllFranch);
            } else {
                // TODO: refactor this
                return;
            }
        };

        getProductsAllFranch();
        setLoading(false);
    }, [roleId, emulation, user.market]);

    const fetchProductsAllFranch = async () => {
        await dispatch(setProductsAllFranch());
    };

    useEffect(() => {
        const hiddenProducts = rows.filter((item) => item.hide);
        setHiddenRows(hiddenProducts);
    }, [rows]);

    // отримати лише активні товари товари
    const getActiveProducts = async () => {
        if (roleId === 3 && !emulation.length) {
            return;
        }
        
        const res = await BankProductsService.GetActiveProducts();

        const products = res
            ? res.map((item) => ({
                id: item.id,
                title: item.title,
                amount: item.amount || 0,
                price: item.price,
                vip: item.vip || null,
                img: item.img,
                promo: item.promo,
                detail: item.detail,
            }))
            : [];

        setRows(products);
        setLoading(false);
    };

    useEffect(() => {
        getActiveProducts();
    }, []);

    // При кліку на кнопку видалити
    function onDeleteProductClick(rowId) {
            dispatch(
                openRequestModal({
                    opened: true,
                    data: {
                        id: rowId, // id товара
                        header: t(
                            'storefrontComponent.titleModalRemoveProduct'
                        ),
                        modaltype: 'ConfirmRemoveProduct',
                    },
                })
            );
    }

    async function onVisibilityOfProductClick(rowId) {
            try {
                const res = await BankProductsService.GetProductById(rowId);
                const visible = res.hide;
                const data = {
                    id: Number(rowId),
                    hide: !visible,
                };
                await BankProductsService.HideProductById(data);

                await fetchProductsAllFranch();
            } catch (error) {
                console.error(error);
            }
    }

    const handleOpenEditProductModal = (rowId) => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: rowId,
                    header: t('storefrontComponent.titleModalEditProduct'),
                    modaltype: 'EditProduct',
                },
            })
        );
    };

    const handleOpenEditQuantityModal = (rowId) => {
        dispatch(
            openRequestModal({
                opened: true,
                data: {
                    id: rowId,
                    header: t(
                        'storefrontComponent.titleModalEditProductQuantity'
                    ),
                    modaltype: 'ЕditProductQuantity',
                },
            })
        );
    };

    if (loading) {
        return (
            <Paper elevation={0}>
                <Box m={2} p={2}>
                    <CircularProgress />
                </Box>
            </Paper>
        );
    }

    if (!loading) {
        return (
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        spacing={2}
                    ></Stack>
                    <TableContainer style={{ height: '69vh' }}>
                        <Table
                            stickyHeader
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                        >
                            <EnhancedTableHead />
                            <TableBody>
                                {sortedRows.map((row, index) => {
                                    const isRowHidden = hiddenRows
                                        .map((item) => item.id)
                                        .includes(row.id);
                                    const labelId = ` enhanced - table - checkbox - ${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                        >
                                            {roleId === 3 &&
                                                !emulation.length && (
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                    >
                                                        {row.promo}
                                                    </TableCell>
                                                )}
                                            <TableCell
                                                align="left"
                                                style={{
                                                    display: 'flex',
                                                    alignContent: 'center',
                                                }}
                                            >
                                                <img
                                                    src={row.img}
                                                    alt="Product Image"
                                                    style={{
                                                        width: '70px',
                                                        height: '70px',
                                                        borderRadius: '7px',
                                                        objectFit: 'cover',
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.title}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.amount || 0}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.price}
                                            </TableCell>
                                            <TableCell align="left">
                                                {+row.price !== +row.vip ? row.vip : null}
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                {roleId === 3 &&
                                                    !emulation.length && (
                                                        <Stack
                                                            direction="row"
                                                            spacing={2}
                                                            sx={{
                                                                mr: '10px',
                                                            }}
                                                        >
                                                            <Tooltip
                                                                title={t(
                                                                    'storefrontComponent.editProductQuantity'
                                                                )}
                                                            >
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleOpenEditQuantityModal(
                                                                            row.id
                                                                        )
                                                                    }
                                                                >
                                                                    <ProductionQuantityLimitsIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={t(
                                                                    'storefrontComponent.editProduct'
                                                                )}
                                                            >
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleOpenEditProductModal(
                                                                            row.id
                                                                        )
                                                                    }
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={t(
                                                                    'storefrontComponent.deleteProduct'
                                                                )}
                                                            >
                                                                <IconButton
                                                                    onClick={() =>
                                                                        onDeleteProductClick(
                                                                            row.id
                                                                        )
                                                                    }
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={
                                                                    isRowHidden
                                                                        ? t(
                                                                              'storefrontComponent.productHidden'
                                                                          )
                                                                        : t(
                                                                              'storefrontComponent.visibleProduct'
                                                                          )
                                                                }
                                                            >
                                                                <IconButton
                                                                    onClick={() =>
                                                                        onVisibilityOfProductClick(
                                                                            row.id
                                                                        )
                                                                    }
                                                                >
                                                                    {isRowHidden ? (
                                                                        <VisibilityOffIcon
                                                                            style={{
                                                                                color: 'red',
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <VisibilityIcon
                                                                            style={{
                                                                                color: 'green',
                                                                            }}
                                                                        />
                                                                    )}
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Stack>
                                                    )}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Box>
        );
    }
}
