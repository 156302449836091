import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';

import { openRequestModal } from '../../actions/modals';
import { getAllStatData } from '../../actions/hw';
import { getCitiesStat } from '../../actions/cities';
import { newEqResetHw } from '../../actions/groups';
import {
    getMultStat,
    getNewEqPointStat,
    getNewEqProgressStat,
} from '../../actions/homeWork';
import { getGroupHistoryHw } from '../../actions/groups';

import {
    ButtonGroup,
    Button,
    Skeleton,
    Stack,
    Box,
    Divider,
    CircularProgress,
} from '@mui/material';

import Grow from '@material-ui/core/Grow';

import MultTable from './MultTable/index';
import PointTable from './PointTable/index';
import ProgressTale from './ProgressTable';
import { AllHWTopic } from '../../constants/hw';

import '../../layout/Dash/Dash.css';
import ClearAllIcon from '@mui/icons-material/ClearAll';

class CandleStickCharts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resetBtn: false,
            multTableData: {},
            pointTableData: null,
            progressTableData: null,
            data: [],
            plugins: [],
            res: [],
            ready: [],
            choosen_shema: 0,
            aver: [],
            _res: [],
            choosen_type: 'Ded',
            series: [
                {
                    name: 'candle',
                    data: [
                        {
                            x: 1,
                            y: [10, 0, 100, 55],
                        },
                    ],
                },
            ],
            series_mind: [
                {
                    name: 'candle',
                    data: [
                        {
                            x: 1,
                            y: [10, 0, 100, 55],
                        },
                    ],
                },
            ],
            options: {
                chart: {
                    height: 350,
                    type: 'candlestick',
                    toolbar: false,
                },
                annotations: {
                    xaxis: [
                        {
                            x: 'Oct 06 14:00',
                            borderColor: '#00E396',
                            label: {
                                borderColor: '#00E396',
                                style: {
                                    fontSize: '12px',
                                    color: '#fff',
                                    background: '#00E396',
                                },
                                orientation: 'horizontal',
                                offsetY: 7,
                                text: 'Annotation Test',
                            },
                        },
                    ],
                },
                tooltip: {
                    enabled: true,
                    custom: function ({ seriesIndex, dataPointIndex, w }) {
                        const o =
                            w.globals.seriesCandleO[seriesIndex][
                                dataPointIndex
                            ];
                        const h =
                            w.globals.seriesCandleH[seriesIndex][
                                dataPointIndex
                            ];
                        const l =
                            w.globals.seriesCandleL[seriesIndex][
                                dataPointIndex
                            ];
                        return (
                            '<div class="apexcharts-tooltip-candlestick">' +
                            '<div>Среднее по городу: <span class="value">' +
                            o +
                            '</span></div>' +
                            '<div>Макс ученика: <span class="value">' +
                            h +
                            '</span></div>' +
                            '<div>Мин ученика: <span class="value">' +
                            l +
                            '</div>'
                        );
                    },
                },
                plotOptions: {
                    candlestick: {
                        colors: {
                            upward: '#f36630',
                            downward: '#59a314',
                        },
                    },
                },
                yaxis: {
                    tooltip: {
                        enabled: true,
                    },
                },
            },
        };
        this.callData();
    }

    componentDidMount() {
        // this.getMultStat();
        this.getPointStat();
        this.getProgressStat();
        const newEq =
            this.props.groups &&
            this.props.groups.eq_option &&
            this.props.groups.eq_option === 2;
        if (newEq) this.setState({ choosen_type: '+' });
    }

    getRoleId() {
        return this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
    }

    async getProgressStat() {
        const role_id = this.getRoleId();
        const res = await getNewEqProgressStat(
            role_id === 5
                ? false
                : { student_id: this.props.data.id, size: 9999 }
        );
        this.setState({
            progressTableData: res,
        });
    }

    async getPointStat() {
        const role_id = this.getRoleId();
        const res = await getNewEqPointStat(
            role_id === 5 ? false : { student_id: this.props.data.id }
        );
        this.setState({
            pointTableData: res,
        });
    }

    async getMultStat() {
        const role_id = this.getRoleId();
        const tableData = await getMultStat(
            role_id === 5 ? false : { student_id: this.props.data.id }
        );

        this.setState({ multTableData: tableData });
    }

    async callData() {
        const role_id = this.getRoleId();
        const averege = await this.props.getCitiesStat(false, {
            id: this.props.data.id,
        });
        const res = await this.props.getAllStatData(
            false,
            role_id === 5 ? false : { student_id: this.props.data.id }
        );
        let _averege = [];
        Object.keys(averege['Ded']).map((el, index) => {
            let _res = [];
            for (let item of Object.keys(averege['Ded'][el])) {
                let labels = [];
                let data = [];
                for (let _i of Object.keys(averege['Ded'][el][item])) {
                    if (_i !== 'S1') {
                        labels.push([_i, averege['Ded'][el][item][_i]]);
                        data.push(averege['Ded'][el][item][_i]);
                    }
                }
                _res.push({ labels, data });
            }
            let _oj = {};
            _oj[el] = _res;
            return _averege.push(_oj);
        });
        let _ready = [];
        Object.keys(res.schemaResponse['Ded']).map((el, index) => {
            let _res = [];
            for (let item of Object.keys(res.schemaResponse['Ded'][el])) {
                let labels = [];
                let data = [];
                for (let _i of Object.keys(
                    res.schemaResponse['Ded'][el][item]
                )) {
                    if (_i !== 'S1') {
                        const minmax = res.schemaResponse['Ded'][el][item][_i];
                        labels.push({
                            x: _i,
                            y: [minmax.min, minmax.max, minmax.min, minmax.max],
                        });
                        data.push(minmax);
                    }
                }
                _res.push({ labels, data });
            }
            let _oj = {};
            _oj[el] = _res;
            return _ready.push(_oj);
        });
        await this.setState({
            ready: [_ready, _averege],
            aver: averege,
            _res: res,
        });
        setTimeout(() => {
            this.enableGraphRender(0);
        }, 200);
    }

    processGameChange(items) {
        let _averege = [];

        Object.keys(this.state.aver[items]).map((el, index) => {
            let _res = [];
            for (let item of Object.keys(this.state.aver[items][el])) {
                let labels = [];
                let data = [];
                for (let _i of Object.keys(this.state.aver[items][el][item])) {
                    if (_i !== 'S1') {
                        labels.push([_i, this.state.aver[items][el][item][_i]]);
                        data.push(this.state.aver[items][el][item][_i]);
                    }
                }
                _res.push({ labels, data });
            }
            let _oj = {};
            _oj[el] = _res;
            return _averege.push(_oj);
        });

        let _ready = [];
        Object.keys(this.state._res.schemaResponse[items]).map((el, index) => {
            let _res = [];
            for (let item of Object.keys(
                this.state._res.schemaResponse[items][el]
            )) {
                let labels = [];
                let data = [];
                for (let _i of Object.keys(
                    this.state._res.schemaResponse[items][el][item]
                )) {
                    if (_i !== 'S1') {
                        const minmax =
                            this.state._res.schemaResponse[items][el][item][_i];
                        labels.push({
                            x: _i,
                            y: [minmax.min, minmax.max, minmax.min, minmax.max],
                        });
                        data.push(minmax);
                    }
                }
                _res.push({ labels, data });
            }
            let _oj = {};
            _oj[el] = _res;
            return _ready.push(_oj);
        });
        this.setState({ ready: [_ready, _averege], choosen_type: items });
        setTimeout(() => {
            this.enableGraphRender(0);
        }, 10);
    }

    enableGraphRender(i) {
        let data = [];
        this.state.ready[0] &&
            this.state.ready[0].map((el, index) => {
                const _arer =
                    this.state.ready[1][index][Object.keys(el)[0]][i].labels;
                el[Object.keys(el)[0]][i].labels.map((el) => {
                    let averNum = _arer.find(
                        (element) => element[0] === el.x
                    )[1];
                    if (_arer.find((element) => element[0] === el.x)) {
                        el.y[0] = averNum;
                        el.y[3] =
                            averNum > el.y[1]
                                ? el.y[1]
                                : averNum < el.y[2]
                                ? el.y[2]
                                : averNum - 20;
                    }
                    return true;
                });
                data.push(el[Object.keys(el)[0]][i].labels);
                return true;
            });
        let series = [{ name: 'candle', data: data[0] }];
        let series_mind = [
            {
                name: 'candle',
                data: data[1],
            },
        ];
        this.setState({ data, choosen_shema: i, series, series_mind });
    }

    processDataMult() {
        this.setState({
            choosen_type: 'Mult',
        });
    }

    processPointType(el) {
        this.setState({
            choosen_type: el,
        });
    }

    handleReset = async () => {
        this.setState({
            resetBtn: true,
        });
        let data = await this.props.getGroupHistoryHw(false, {
            id: this.props.groups && this.props.groups.id,
            size: 999,
        });
        const topic =
            data &&
            data.list &&
            data.list.length &&
            Math.max.apply(
                null,
                data.list
                    .map(
                        (item) =>
                            AllHWTopic.find(
                                (el) =>
                                    el.label.replace(/\s/g, '') === item.topic
                            ).value
                    )
                    .filter((el) => el < 53)
            );
        if (data.list.length) {
            try {
                await newEqResetHw(
                    {
                        student_id: this.props.data.id,
                        topic,
                    },
                    false
                );
                // this.getMultStat();
                this.getPointStat();
                this.getProgressStat();
            } catch (e) {
                console.log(e);
            }
        }

        this.setState({
            resetBtn: false,
        });
    };

    render() {
        const { options, series, series_mind } = this.state;
        const { t } = this.props;
        const newEq =
            this.props.groups &&
            this.props.groups.eq_option &&
            this.props.groups.eq_option === 2;
        const schemaStat = newEq
            ? ['+', '-', 'Mult', '!']
            : ['Ded', 'Flash', 'FlashMinus', 'Mult'];
        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        return (
            <div>
                {this.state.data.length ? (
                    <div>
                        <Stack mb={5} spacing={2} alignItems={'flex-start'}>
                            {newEq &&
                            role_id &&
                            role_id === 4 &&
                            (this.state.choosen_type === '+' ||
                                this.state.choosen_type === '-') ? (
                                <Button
                                    variant={'outlined'}
                                    onClick={this.handleReset}
                                    disabled={this.state.resetBtn}
                                    endIcon={<ClearAllIcon />}
                                    style={{ textTransform: 'usnet' }}
                                >
                                    {(this.state.resetBtn && (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    )) ||
                                        'Reset'}
                                </Button>
                            ) : null}
                            <div className="stat_title_digit">
                                {newEq ? t('table.actions') : 'Тип игры'}
                            </div>
                            <Box>
                                {schemaStat.map((el, index) => (
                                    <Button
                                        variant="outlined"
                                        style={{
                                            marginRight: 5,
                                        }}
                                        className={
                                            this.state.choosen_type === el
                                                ? 'choosen_btn'
                                                : ''
                                        }
                                        key={index}
                                        onClick={() => {
                                            el === 'Mult'
                                                ? this.processDataMult(el)
                                                : el === '+' ||
                                                  el === '-' ||
                                                  el === '!'
                                                ? this.processPointType(el)
                                                : this.processGameChange(el);
                                        }}
                                    >
                                        {el}
                                    </Button>
                                ))}
                            </Box>
                        </Stack>

                        {this.state.choosen_type === 'Mult' ||
                        (newEq && this.state.choosen_type === '+') ||
                        (newEq && this.state.choosen_type === '-') ||
                        (newEq && this.state.choosen_type === '!') ? (
                            <>
                                {this.state.choosen_type !== 'Mult' ? (
                                    newEq && this.state.choosen_type === '!' ? (
                                        <Grow in={true} timeout={500}>
                                            <ProgressTale
                                                data={
                                                    this.state.progressTableData
                                                }
                                            />
                                        </Grow>
                                    ) : (
                                        <Grow in={true} timeout={500}>
                                            <PointTable
                                                data={this.state.pointTableData}
                                                type={this.state.choosen_type}
                                            />
                                        </Grow>
                                    )
                                ) : (
                                    <Grow in={true} timeout={500}>
                                        <MultTable
                                            data={this.state.multTableData}
                                        />
                                    </Grow>
                                )}
                            </>
                        ) : (
                            <>
                                <Stack>
                                    <div>{t('table.digit')}</div>

                                    <Box>
                                        <ButtonGroup
                                            size="large"
                                            color="inherit"
                                            aria-label="large outlined primary button group"
                                        >
                                            {[1, 2, 3, 4].map((el, index) => (
                                                <Button
                                                    className={
                                                        this.state
                                                            .choosen_shema ===
                                                        el - 1
                                                            ? 'choosen_btn'
                                                            : ''
                                                    }
                                                    key={index}
                                                    onClick={() =>
                                                        this.state
                                                            .choosen_shema ===
                                                        el - 1
                                                            ? null
                                                            : this.enableGraphRender(
                                                                  el - 1
                                                              )
                                                    }
                                                >
                                                    {el}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </Box>
                                </Stack>
                                <div className="data_line">
                                    <h3>{t('dashboard.eabacus')}</h3>
                                    <ReactApexChart
                                        options={options}
                                        series={series}
                                        type="candlestick"
                                        height={350}
                                    />
                                </div>
                                <Box pt={4}>
                                    <Divider />
                                </Box>
                                <div className="data_line">
                                    <h3>{t('table.mind')}</h3>
                                    <ReactApexChart
                                        options={options}
                                        series={series_mind}
                                        type="candlestick"
                                        height={350}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div>
                        <Stack spacing={5}>
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={'50%'}
                                height={40}
                            />
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={'100%'}
                                height={300}
                            />
                            <Skeleton
                                animation="wave"
                                variant="rect"
                                width={'100%'}
                                height={300}
                            />
                        </Stack>
                    </div>
                )}
            </div>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        modalWindow: state.modalWindow,
        users: state.users,
        books: state.books,
        groups: state.groups && state.groups.single,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                getCitiesStat,
                getAllStatData,
                getGroupHistoryHw,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(CandleStickCharts));
