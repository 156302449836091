import { ApiConnector, GET, POST, PUT, DELETE } from './request.service';

export default (() => {
    const API = ApiConnector('/cities');
    return {
        CitiesList: async (data, query) =>
            await API(GET, '', false, query).call(data),
        AddCity: async (data, query) =>
            await API(POST, '', false, query).call(data),
        EditCity: async (data, query) =>
            await API(PUT, '', false, query).call(data),
        DeleteCity: async (data, query) =>
            await API(DELETE, '', false, query).call(data),
        SingleCity: async (data, query) =>
            await API(GET, '/single', false, query).call(data),
        GetCitiesStat: async (data, query) =>
            await API(GET, '/stat', false, query).call(data),
        RelocateCitiesAvaliable: async (data, query) =>
            await API(GET, '/relocate', false, query).call(data),
        GetCurrentPrice: async () => await API(GET, '/avg-price').call(),
        ChangeBlockDay: async (data) =>
            await API(POST, '/block-day').call(data),
        GetBlockDay: async () => await API(GET, '/block-day').call(),
        ChangeCityPrice: async (data) =>
            await API(POST, '/avg-price').call(data),
        GetCurrentLiPrice: async () => await API(GET, '/avg-price-li').call(),
        ChangeCityLiPrice: async (data) =>
            await API(POST, '/avg-price-li').call(data),
        PostFranchPriceOnline: async (data) =>
            await API(POST, '/avg-price-online').call(data, true),
        PostFranchPriceOnlineLi: async (data) =>
            await API(POST, '/avg-price-li-online').call(data, true),
        GetFranchPriceOnline: async (data) =>
            await API(GET, '/avg-price-online').call(data),
        GetFranchPriceOnlineLi: async (data) =>
            await API(GET, '/avg-price-li-online').call(data),
    };
})();
