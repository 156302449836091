import React, { Fragment } from "react";
import ComponentTable from "../../components/ComponentTable";

import { connect } from "react-redux";
import { closeRequestModal, openRequestModal } from "../../actions/modals";
import { bindActionCreators } from "redux";
import { Add, Edit, Delete } from "@material-ui/icons";
import { getOffices } from "../../actions/offices";
import OfficesService from "../../services/offices.service";
import ModelService from "../../services/models.service";
import { withTranslation } from "react-i18next";
class OfficesPage extends React.Component {
  componentDidMount() {}

  render() {
    const { t } = this.props;
    const actions = [
      {
        title: t("modals_name.edit"),
        onClick: (id) =>
          this.props.openRequestModal({
            opened: true,
            data: {
              id: id,
              modaltype: "EditOfficeModal",
              header: t("modals_name.edit"),
            },
          }),
        Icon: Edit,
      },
      {
        title: t("buttons.delete"),
        onClick: (id) =>
          this.props.openRequestModal({
            opened: true,
            data: {
              id: id,
              header: t("buttons.delete"),
              modaltype: "DeleteOfficeModal",
            },
          }),
        Icon: Delete,
      },
    ];
    return (
      <Fragment>
        <h2 className="container-header"> Офiси </h2>
        <ComponentTable
          source={{
            getData: (page, size, sort, filter) =>
              OfficesService.OfficesList(false, {
                page: page || 0,
                size: size || 10,
                orderBy: (sort && sort.orderBy) || null,
                order: (sort && sort.order) || null,
                filter,
              }),
            getModel: () => ModelService.OfficesList(false),
          }}
          actions={actions}
          selectable={true}
          enableFilter={true}
          title="list offices"
          enableSettings
          actionsButton={[
            {
              title: "Добавить",
              Icon: Add,
              onClick: () =>
                this.props.openRequestModal({
                  opened: true,
                  data: {
                    header: t("modals_name.add_office"),
                    modaltype: "AddOfficeModal",
                  },
                }),
            },
          ]}
          toolbarvisible={true}
        />
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({ user: state.user, offices: state.offices }),
  (dispatch) =>
    bindActionCreators(
      { getOffices, openRequestModal, closeRequestModal },
      dispatch
    )
);

export default withTranslation()(widthConnect(OfficesPage));
