import React, { Fragment } from "react";
import CountriesService from "../../services/countries.service";
import SCHEMAS from "../../constants/modalSchemas";

import UsersService from "../../services/users.service";
import ModelService from "./../../services/models.service";
import { connect } from "react-redux";
import { closeRequestModal, openRequestModal } from "../../actions/modals";
import { bindActionCreators } from "redux";
import ComponentTable from "../../components/ComponentTable";

import { Add, Edit, Delete } from "@material-ui/icons";

class CountriesPage extends React.Component {
  componentDidMount() {}

  render() {
    const actions = [
      {
        title: "Редагувати",
        onClick: (id) =>
          this.props.openRequestModal({
            opened: true,
            data: {
              id: id,
              modaltype: "EditCountryModal",
              header: "Редагувати",
            },
          }),
        Icon: Edit,
      },
      {
        title: "Видалити",
        onClick: (id) =>
          this.props.openRequestModal({
            opened: true,
            data: {
              id: id,
              schema: SCHEMAS.requestDelete,
              header: "Дiйсно видалити?",
              modaltype: "DeleteCountryModal",
            },
          }),
        Icon: Delete,
      },
    ];
    return (
      <Fragment>
        <h2 className="container-header"> Країни </h2>
        <ComponentTable
          source={{
            getData: (page, size, sort, filter) =>
              CountriesService.CountriesList(false, {
                page: page || 0,
                size: size || 10,
                orderBy: (sort && sort.orderBy) || null,
                order: (sort && sort.order) || null,
                filter,
              }),
            getModel: () => ModelService.CountriesList(false),
          }}
          actions={actions}
          selectable={true}
          enableFilter={false}
          title="Список пользователей"
          enableSettings
          toolbarvisible={true}
          actionsButton={[
            {
              title: "Добавить",
              Icon: Add,
              onClick: (id) =>
                this.props.openRequestModal({
                  opened: true,
                  data: {
                    schema: SCHEMAS.bossAddCountry,
                    header: "Додати країну",
                    modaltype: "AddCountryModal",
                  },
                }),
            },
          ]}
        />
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({
    user: state.user,
    modalWindow: state.modalWindow,
    users: state.users,
  }),
  (dispatch) =>
    bindActionCreators({ openRequestModal, closeRequestModal }, dispatch)
);

export default widthConnect(CountriesPage);
