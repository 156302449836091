//* ↓↓ LIST OF APIs ↓↓
const PROD_API = 'https://api.soroban.ua/panel';
const TEST_API = 'https://api-test.soroban.ua/panel';
const STAGING_API = 'https://api-staging.soroban.ua/panel';
const TEST_LOCAL_API = 'http://local.soroban.ua:8888/panel';

const HOSTNAME = window.location.hostname;

let URL = PROD_API;

// 1 true 0 false
const LOCAL_BACK = 0;
const PROD = 0;
const STAGING = 0;

//* ↓↓ API links process ↓↓
if (HOSTNAME.includes('local') || HOSTNAME.includes('192.168')) {
    URL =
        (PROD && PROD_API) ||
        (STAGING && STAGING_API) ||
        (LOCAL_BACK && TEST_LOCAL_API) ||
        TEST_API;
} else if (HOSTNAME.includes('test') || HOSTNAME.includes('sap')) {
    URL = TEST_API;
} else if (HOSTNAME.includes('staging')) {
    URL = STAGING_API;
}

function getURL() {
    if (window.location.pathname.includes('/hidden')) {
        return PROD_API;
    }
}

export { getURL };

export default URL;
