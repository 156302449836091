import { func } from 'prop-types';

export function processCountryPhone() {
    const domainLast =
        document.location.hostname.split('.')[
            document.location.hostname.split('.').length - 1
        ];
    return domainLast === 'com' ? 'ca' : domainLast;
}

export function detectPhone() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return true;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
    } else if (window.outerWidth <= 768) {
        return true;
    } else {
        return false;
    }
}
