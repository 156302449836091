import React, { Fragment, useEffect, useMemo } from 'react';
import './GroupsPage.css';
import ChoosePlate from '../../components/ChoosePlate';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Add, Edit, Delete } from '@material-ui/icons';
import ComponentTable from '../../components/ComponentTable';
import GroupsService from './../../services/groups.service';
import ModelService from './../../services/models.service';
import { closeRequestModal, openRequestModal } from '../../actions/modals';
import { getTeachersFromSelect } from '../../actions/groups';
import { getListToPlate } from '../../actions/groups';
import { getOffices } from '../../actions/offices';

import { withTranslation } from 'react-i18next';
import ScheduleComponent from './ScheduleComponent';

const GroupsPage = (props) => {
    const { t } = props;
    const user = !!props.user.emulation.length
        ? props.user.emulation[props.user.emulation.length - 1]
        : props.user.info;
    const role_id = !!props.user.emulation.length
        ? user.info.role_id
        : user.role_id;
    const user_id = user.user_id;

    useEffect(() => {
        getListToPlate(false, { id: 3 });
        props.getOffices(false, { size: 999 });
    }, []);

    const actions = useMemo(() => {
        const result = [];
        if (role_id !== 5) {
            result.push({
                title: t('groups.edit_group_data'),
                onClick: (id) =>
                    props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: t('modals_name.edit'),
                            modaltype: 'EditGroupModal',
                        },
                    }),
                outOfMenu: true,
                Icon: Edit,
            });
        }
        if (role_id < 4) {
            result.push({
                title: t('table.delete'),
                onClick: (id) =>
                    props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: t('table.delete'),
                            modaltype: 'DeleteGroupModal',
                        },
                    }),
                Icon: Delete,
            });
        }
        return result;
    }, [role_id]);

    const addGroupData = useMemo(() => {
        return [
            {
                title: t('table.add'),
                Icon: Add,
                onClick: () =>
                    props.openRequestModal({
                        opened: true,
                        data: {
                            header: t('modals_name.add_group'),
                            modaltype: 'AddGroupModal',
                            teacher_id: user_id,
                        },
                    }),
            },
        ];
    }, [user_id]);

    const addGroup = useMemo(() => {
        return role_id !== 5 ? addGroupData : null;
    }, [role_id]);
    return (
        <Fragment>
            <h2 className="container-header">{t('groups.title')} </h2>
            {role_id === 3 ? <ScheduleComponent /> : null}
            <ComponentTable
                source={{
                    firstLoad: () =>
                        props.getTeachersFromSelect(false, {
                            size: 9999,
                        }),
                    getData: (page, size, sort, filter) =>
                        GroupsService.GroupsList(false, {
                            role_id: +role_id,
                            page: page || 0,
                            size: size || 10,
                            orderBy: (sort && sort.orderBy) || 'lesson_day',
                            order: (sort && sort.order) || 'asc',
                            filter,
                        }),
                    getModel: () => ModelService.GroupsList(),
                }}
                actions={actions}
                selectable={true}
                title={t('groups.title')}
                toolbarvisible={true}
                actionsButton={addGroup}
                enableFilter={true}
                typeTable="single_hw"
            />
        </Fragment>
    );
};

const widthConnect = connect(
    (state) => ({
        user: state.user,
        modalWindow: state.modalWindow,
        users: state.users,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                closeRequestModal,
                getTeachersFromSelect,
                getOffices,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(GroupsPage));
