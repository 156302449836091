import { useState, useEffect } from 'react';
import { detectPhone } from '../actions/phone';

const usePhoneCheck = () => {
    const [device, setDevice] = useState(false);

    useEffect(() => {
        const isPhone = detectPhone();
        setDevice(isPhone);
    }, []);

    return device;
};

export default usePhoneCheck;
