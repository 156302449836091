import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../store';
import { openRequestModal } from '../../actions/modals';
import {
    setToken,
    setBalance,
    setPendingTransactions,
    setPendingRootTransactions,
    setPendingOrders,
    setTeachersBalances,
    setStudentsBalances,
    setCitiesBalances,
} from '../../actions/bank';
import BankCustomersService from '../../services/bank-customers.service';
import useCheckUserId from '../../hooks/useCheckUserId';
import { useTranslation } from 'react-i18next';

import {
    Paper,
    Box,
    Typography,
    Button,
    Stack,
    CircularProgress,
    Collapse,
} from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
import ReplyIcon from '@mui/icons-material/Reply';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UpdateIcon from '@mui/icons-material/Update';
import IconButton from '@material-ui/core/IconButton';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import CustomLoadingSkeleton from './CustomLoadingSkeleton';
import { useHistory } from 'react-router-dom';
import style from './BalanceComponent.module.css';

export default function BalanceComponent(props) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingBalance, setLoadingBalance] = useState(false);
    const { emulation } = store.getState().user;
    const hasEmulation =
        emulation.length && emulation[emulation.length - 1].info;

    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isUserIdOne = useCheckUserId();
    const userId = useSelector((state) => state.user.info.user_id);
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;
    const roleId = useSelector((state) => state.user.info.role_id);

    const vip = user.customer.isvip;
    const teachersBalances = user.customer.teachersBalances || [];
    const studentsBalances = user.customer.studentsBalances || [];
    const citiesBalances = user.customer.citiesBalances || [];

    const totalTeachersBalances = useMemo(() => {
        return teachersBalances.reduce(
            (acc, item) => acc + Number(item.teacher_balance),
            0
        );
    }, [teachersBalances]);

    const totalStudentsBalances = useMemo(() => {
        return studentsBalances.reduce(
            (acc, item) => acc + Number(item.student_balance),
            0
        );
    }, [studentsBalances]);

    const totalCitiesBalances = useMemo(() => {
        return citiesBalances.reduce(
            (acc, item) =>
                acc +
                (Number(item.franch_balance) +
                    Number(item.teach_balance) +
                    Number(item.student_balance)),
            0
        );
    }, [citiesBalances]);

    const numberOfPendingTransactions = useMemo(() => {
        // if (roleId === 4) {
        if ((roleId === 4 && !emulation.length) || hasEmulation.role_id === 4) {
            return user.customer.pendingTransactions
                ? user.customer.pendingTransactions.length || '0'
                : '0';
        }
        // if (roleId === 3 || roleId === 2) {
        if (
            (roleId === 2 && !emulation.length) ||
            hasEmulation.role_id === 3 ||
            (roleId === 3 && !emulation.length)
        ) {
            return user.customer.pendingRootTransactions
                ? user.customer.pendingRootTransactions.length || '0'
                : '0';
        }
        return '0';
    }, [
        roleId,
        user.customer.pendingTransactions,
        user.customer.pendingRootTransactions,
    ]);

    const numberOfPendingOrders = useMemo(() => {
        return user.customer.pendingOrders
            ? user.customer.pendingOrders.length || '0'
            : '0';
    }, [user.customer.pendingOrders]);

    const { classes } = props;

    const getCheckExist = async (noLoadingState = true) => {
        const res = await BankCustomersService.GetCheckExist();
        if (res && res.jwt) {
            setIsLoggedIn(noLoadingState);
            dispatch(setToken(res));
            fetchAllData(noLoadingState);
        }
        setTimeout(() => {
            setLoading(false);
        }, 300);
    };

    useEffect(() => {
        getCheckExist();
    }, []);

    const fetchAllData = async (noLoadingState = true) => {
        //if (roleId === 4) {

        if ((roleId === 4 && !emulation.length) || hasEmulation.role_id === 4) {
            await dispatch(setPendingTransactions());
            await dispatch(setPendingOrders());
            await dispatch(setStudentsBalances());
        }
        // if (roleId === 3) {

        if ((roleId === 3 && !emulation.length) || hasEmulation.role_id === 3) {
            await dispatch(setStudentsBalances());
            await dispatch(setTeachersBalances());
        }

        // if (roleId === 3 || roleId === 2) {

        if (
            (roleId === 2 && !emulation.length) ||
            hasEmulation.role_id === 3 ||
            (roleId === 3 && !emulation.length)
        ) {
            await dispatch(setPendingRootTransactions());
        }
        // if (roleId === 2) {
        if (roleId === 2 && !emulation.length) {
            await dispatch(setCitiesBalances());
        }
        await fetchBalance(noLoadingState);
    };

    // Якщо не зареєстрован - реєструємо
    const onRegisterButtonClick = () => {
        const fetchUserData = async () => {
            const res = await BankCustomersService.Registration();
            if (res && res.jwt) {
                setIsLoggedIn(true);
                dispatch(setToken(res));
            }
        };

        fetchUserData();
    };

    const fetchBalance = async (noLoadingState = true) => {
        setLoadingBalance(noLoadingState);
        await dispatch(setBalance());
        setLoadingBalance(false);
    };

    async function handleClick() {
        setLoading(true);
        setLoadingBalance(true);
        await fetchAllData(false);
        setTimeout(() => {
            setLoading(false);
            setLoadingBalance(false);
        }, 500);
    }

    // Функція для виклику модального вікна
    const handleOpenRequestModal = (data) => {
        dispatch(
            openRequestModal({
                opened: true,
                data,
            })
        );
    };

    // Таблиці балансів
    const handleOpenBalancesPage = (path) => {
        history.push(path);
    };

    const formattedNumber = useCallback((number) => {
        return new Intl.NumberFormat(undefined, {
            maximumFractionDigits: 2,
        }).format(number);
    }, []);

    // if (!isUserIdOne || emulation.length) {
    //     return null;
    // }

    return (
        <>
            <Paper
                elevation={0}
                style={{ position: 'relative', marginBottom: '20px' }}
            >
                <Box className={style.container}>
                    <Typography variant={'caption'} className={style.caption}>
                        Soroban BANK
                    </Typography>
                </Box>
                <Collapse in={loading}>
                    <Box p={2} m={2}>
                        <CustomLoadingSkeleton />
                    </Box>
                </Collapse>
                <Collapse in={!loading && !loadingBalance}>
                    <Box m={2} p={2}>
                        {isLoggedIn ? (
                            <div>
                                {/* {roleId === 5 && vip && ( */}
                                {(roleId === 5 || hasEmulation.role_id === 5) &&
                                    vip && (
                                        <Box className={style.vipStatus}>
                                            VIP
                                        </Box>
                                    )}
                                <div className={style.balanceContainer}>
                                    <Box className={style.balanceWrapper}>
                                        <Typography
                                            component="div"
                                            style={{
                                                width: '100px',
                                            }}
                                        >
                                            {t('balanceComponent.balance')}
                                        </Typography>
                                        <Box>
                                            <Typography
                                                className={style.currentBalance}
                                            >
                                                {loadingBalance ? (
                                                    <CircularProgress
                                                        size={13}
                                                    />
                                                ) : (
                                                    formattedNumber(balance)
                                                )}{' '}
                                            </Typography>
                                            <Typography
                                                className={style.currencyLabel}
                                                variant={'caption'}
                                            >
                                                Sorocoins
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Tooltip
                                        title={t(
                                            'balanceComponent.updateBalance'
                                        )}
                                        placement="bottom"
                                    >
                                        <span>
                                            <IconButton
                                                className={
                                                    style.updateBalanceButton
                                                }
                                                onClick={handleClick}
                                                disabled={
                                                    loadingBalance || loading
                                                }
                                                aria-label={t(
                                                    'balanceComponent.updateBalance'
                                                )}
                                            >
                                                <UpdateIcon
                                                    style={{
                                                        color: '#58a315',
                                                    }}
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                </div>

                                {/* {roleId === 2 && ( */}
                                {roleId === 2 && !emulation.length && (
                                    <Button
                                        className={style.buttonOpenBalancesPage}
                                        variant="outlined"
                                        onClick={() =>
                                            handleOpenBalancesPage(
                                                '/dashboard/balances/franch'
                                            )
                                        }
                                        startIcon={<AttachMoneyIcon />}
                                    >
                                        {t('balanceComponent.cities')}{' '}
                                        <span className={style.balanceSummary}>
                                            {formattedNumber(
                                                totalCitiesBalances
                                            )}
                                        </span>
                                        SBK
                                    </Button>
                                )}

                                {/* {roleId === 3 && ( */}

                                {(roleId === 3 && !emulation.length) ||
                                hasEmulation.role_id === 3 ? (
                                    <div className={style.flexContainer}>
                                        <Button
                                            className={
                                                style.buttonOpenBalancesPage
                                            }
                                            variant="outlined"
                                            onClick={() =>
                                                handleOpenBalancesPage(
                                                    '/dashboard/balances/teachers'
                                                )
                                            }
                                            startIcon={<AttachMoneyIcon />}
                                        >
                                            {t('balanceComponent.teachers')}{' '}
                                            <span
                                                className={style.balanceSummary}
                                            >
                                                {formattedNumber(
                                                    totalTeachersBalances
                                                )}
                                            </span>
                                            SBK
                                        </Button>
                                        <Button
                                            className={
                                                style.buttonOpenBalancesPage
                                            }
                                            variant="outlined"
                                            onClick={() =>
                                                handleOpenBalancesPage(
                                                    '/dashboard/balances/students'
                                                )
                                            }
                                            startIcon={<AttachMoneyIcon />}
                                        >
                                            {t('balanceComponent.students')}{' '}
                                            <span
                                                className={style.balanceSummary}
                                            >
                                                {formattedNumber(
                                                    totalStudentsBalances
                                                )}
                                            </span>
                                            SBK
                                        </Button>
                                    </div>
                                ) : null}

                                {(roleId === 4 && !emulation.length) ||
                                hasEmulation.role_id === 4 ? (
                                    <Button
                                        variant="outlined"
                                        className={style.buttonOpenBalancesPage}
                                        onClick={() =>
                                            handleOpenBalancesPage(
                                                '/dashboard/balances/students'
                                            )
                                        }
                                        startIcon={<AttachMoneyIcon />}
                                    >
                                        {t('balanceComponent.students')}{' '}
                                        <span className={style.balanceSummary}>
                                            {formattedNumber(
                                                totalStudentsBalances
                                            )}
                                        </span>
                                        SBK
                                    </Button>
                                ) : null}

                                {/* {(roleId === 2 || roleId === 3 || roleId === 4) && ( */}

                                {(roleId === 2 && !emulation.length) ||
                                hasEmulation.role_id === 3 ||
                                hasEmulation.role_id === 4 ||
                                (roleId === 3 && !emulation.length) ||
                                hasEmulation.role_id === 4 ||
                                (roleId === 4 && !emulation.length) ? (
                                    <Stack
                                        className={style.transactionsContainer}
                                    >
                                        <Typography>
                                            {t(
                                                'balanceComponent.confirmationAwaited'
                                            )}
                                        </Typography>
                                        <Button
                                            className={
                                                style.buttonOpenModalActiveTransactions
                                            }
                                            onClick={() => {
                                                // if (roleId === 4) {

                                                if (
                                                    (roleId === 4 &&
                                                        !emulation.length) ||
                                                    hasEmulation.role_id === 4
                                                ) {
                                                    handleOpenRequestModal({
                                                        id: userId,
                                                        header: t(
                                                            'balanceComponent.titleModalAllActiveTransactions'
                                                        ),
                                                        modaltype:
                                                            'AllActiveTransactions',
                                                    });
                                                } else if (
                                                    (roleId === 2 &&
                                                        !emulation.length) ||
                                                    hasEmulation.role_id ===
                                                        3 ||
                                                    (roleId === 3 &&
                                                        !emulation.length)
                                                ) {
                                                    handleOpenRequestModal({
                                                        id: userId,
                                                        header: t(
                                                            'balanceComponent.titleModalActiveRootTransactions'
                                                        ),
                                                        modaltype:
                                                            'AllActiveRootTransactions',
                                                    });
                                                }
                                            }}
                                        >
                                            {numberOfPendingTransactions || (
                                                <CircularProgress
                                                    color={'error'}
                                                    size={10}
                                                />
                                            )}{' '}
                                            {t(
                                                'balanceComponent.transactionsSBK'
                                            )}
                                        </Button>
                                        {/* {roleId === 4 && ( */}

                                        {(roleId === 4 && !emulation.length) ||
                                        hasEmulation.role_id === 4 ? (
                                            <Button
                                                className={
                                                    style.buttonOpenModalActiveTransactions
                                                }
                                                onClick={() =>
                                                    handleOpenRequestModal({
                                                        id: userId,
                                                        header: t(
                                                            'balanceComponent.titleModalAllActiveOrders'
                                                        ),
                                                        modaltype:
                                                            'AllActiveOrders',
                                                    })
                                                }
                                            >
                                                {numberOfPendingOrders || (
                                                    <CircularProgress
                                                        color={'error'}
                                                        size={10}
                                                    />
                                                )}{' '}
                                                {t(
                                                    'balanceComponent.goodsOrders'
                                                )}
                                            </Button>
                                        ) : null}
                                    </Stack>
                                ) : null}
                                <Box
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="flex-start"
                                >
                                    <Button
                                        className={
                                            style.buttonTransferSorocoins
                                        }
                                        variant="outlined"
                                        onClick={() =>
                                            handleOpenRequestModal({
                                                id: userId,
                                                header: t(
                                                    'balanceComponent.titleModalTransferSorocoins'
                                                ),
                                                modaltype: 'TransferSorocoins',
                                            })
                                        }
                                        startIcon={<ReplyIcon />}
                                    >
                                        {t('balanceComponent.moneyTransfers')}
                                    </Button>

                                    {(roleId === 3 && !emulation.length) ||
                                    (roleId === 4 && !emulation.length) ||
                                    hasEmulation.role_id === 4 ? (
                                        <Button
                                            className={
                                                style.buttonTransferSorocoins
                                            }
                                            variant="outlined"
                                            onClick={() =>
                                                handleOpenRequestModal({
                                                    id: userId,
                                                    header: t(
                                                        'balanceComponent.titleModalOrderSorocoins'
                                                    ),
                                                    modaltype: 'OrderSorocoins',
                                                })
                                            }
                                        >
                                            {t(
                                                'balanceComponent.orderSoroCoins'
                                            )}
                                        </Button>
                                    ) : null}
                                    {/* {roleId === 5 && ( */}
                                    {roleId === 5 ||
                                    hasEmulation.role_id === 5 ? (
                                        <>
                                            <Button
                                                className={
                                                    style.buttonDepositMoney
                                                }
                                                variant="contained"
                                                onClick={() =>
                                                    handleOpenRequestModal({
                                                        id: userId,
                                                        header: t(
                                                            'balanceComponent.titleModalDepositMoney'
                                                        ),
                                                        modaltype:
                                                            'DepositMoney',
                                                    })
                                                }
                                                startIcon={<AttachMoneyIcon />}
                                            >
                                                {t(
                                                    'balanceComponent.depositMoney'
                                                )}
                                            </Button>
                                            <Button
                                                className={
                                                    style.buttonDepositMoney
                                                }
                                                variant="contained"
                                                color={'error'}
                                                onClick={() =>
                                                    handleOpenRequestModal({
                                                        id: userId,
                                                        header: t(
                                                            'balanceComponent.titleModalWithdrawMoney'
                                                        ),
                                                        modaltype:
                                                            'WithdrawMoney',
                                                    })
                                                }
                                                startIcon={<MoneyOffIcon />}
                                            >
                                                {t(
                                                    'balanceComponent.withdrawMoney'
                                                )}
                                            </Button>
                                        </>
                                    ) : null}
                                </Box>
                            </div>
                        ) : (
                            <Button
                                className={style.buttonRegisterInSOROBANK}
                                variant="contained"
                                startIcon={<AccountBalanceIcon />}
                                onClick={onRegisterButtonClick}
                            >
                                {t('balanceComponent.registerInSOROBANK')}
                            </Button>
                        )}
                    </Box>
                </Collapse>
            </Paper>
        </>
    );
}
