import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    Stack,
    Checkbox,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tooltip,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';


export default function BankMobileBody(props) {
    const {
        rows,
        headCells,
        isSelected,
        handleSelectAllClick,
        handleQuantityChange,
        handleClick,
        renderQuantityInput,
        showCurrentStatus,
        handleBankAccountClick,
        roundToTwoDecimals,
        roleId,
        emulation,
    } = props;

    const { t, i18n } = useTranslation();

    return (
        <>
            {rows.length ?
                (<Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    marginTop={'12px'}
                    marginBottom={'12px'}
                >
                    <Checkbox
                        color="primary"
                        onChange={handleSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all',
                        }}
                    />
                    <Typography>{t('activeTransactions.selectAll')}</Typography>
                </Stack>
                ) : (<></>)}
            {rows.map((row, index) => {
                const isItemSelected = isSelected(row.rowId);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                    <Accordion key={row.rowId}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            sx={{
                                bgcolor: '#e4f9e8 !important',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                            }}
                        >
                            {row.surname} {row.name}
                            {row.productName ? (
                                <span>
                                    {row.productName}
                                    <span style={{ marginLeft: '20px' }}>{row.quantity} шт</span>
                                </span>
                            ) : ''}
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack
                                width='100%'
                                direction="column"
                                justifyContent="space-between"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Checkbox
                                        color="primary"
                                        onClick={(event) => handleClick(event, row.rowId)}
                                        checked={isItemSelected}
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </Stack>
                                {headCells.map((headCell) => {
                                    if (headCell.id === 'teacher' && !((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length))) {
                                        return null;
                                    }

                                    return (
                                        <Stack
                                            sx={{ width: '100%' }}
                                            key={headCell.id}
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Typography>{t(headCell.label)}</Typography>
                                            {headCell.id === 'quantity' ? (
                                                renderQuantityInput(row.rowId, row.quantity, handleQuantityChange)
                                            ) : headCell.id === 'status' ? (
                                                showCurrentStatus(row.status, { t, i18n })
                                            ) : headCell.id === 'bankAccount' ? (
                                                <Tooltip
                                                    title="SOROBANK"
                                                    placement="bottom"
                                                >
                                                    <Button
                                                        onClick={() => handleBankAccountClick(row.rowId)}
                                                        style={{
                                                            height: '40px',
                                                            borderRadius: '20px',
                                                            fontSize: '14px',
                                                            color: '#58a315',
                                                        }}
                                                        aria-label="SOROBANK"
                                                        type="button"
                                                        startIcon={
                                                            <AccountBalanceIcon
                                                                style={{ color: '#58a315' }}
                                                            />
                                                        }
                                                    >
                                                        SOROBANK
                                                    </Button>
                                                </Tooltip>
                                            ) : headCell.id === 'group' ? (
                                                <Typography>
                                                    {typeof row.group === 'object'
                                                        ? Object.values(row.group).find(value => value !== null && value !== undefined && value !== '') || ''
                                                        : row.group || ''}
                                                </Typography>
                                            ) : headCell.id === 'productName' ? (
                                                <Stack
                                                    direction="column"
                                                    justifyContent="flex-end"
                                                    alignItems="flex-end"
                                                    spacing={2}
                                                >
                                                    <img
                                                        width="50"
                                                        src={row.img}
                                                        alt={row.productName}
                                                    />
                                                    <Typography
                                                        width={200}
                                                        textAlign={'right'}
                                                        variant="body2"
                                                        color="text.secondary"
                                                    >
                                                        {row.productName}
                                                    </Typography>
                                                </Stack>
                                            ) : headCell.id === 'sum' ? (
                                                <Typography>
                                                    {`${roundToTwoDecimals(row.price * row.quantity)} SBK`}
                                                </Typography>
                                            ) : (
                                                <Typography>{row[headCell.id]}</Typography>
                                            )}
                                        </Stack>
                                    );
                                })}
                            </Stack>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    );
};
